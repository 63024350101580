import React from 'react'
import {Icon } from 'antd'
// import lodingImage from "../loading.gif"

export default function Loading(props) {
    const {height,text,loading,children} = props
    const style = {
        ct: {
            height: height || '100vh',
            position: ' relative',
        },
        vt: {
            fontSize:"larger",
            margin: 0,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width:"100%",
            textAlign:"center",
            fontWeight:"600"
        },
        img:{
            width:50,
            margin: '0 auto',
            display: 'block'
        }
    }

    const loader = (  
        <div style={style.ct}>
        <div className="" style={style.vt}>
            {/* <img alt="loading" src={lodingImage} style={style.img}/> */}
            <Icon type="loading"/>{text || " Hold tight—just getting this page ready"}
        </div>

    </div>)
    return (
        
            children ?
            loading ? loader : children
            :
            loader

      
    )
}

