import React, { forwardRef, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import  { FeatureCard } from '../../../../components/DashboardActions'
import webcam from "../../../../assets/images/webcam.png"
import link from "../../../../assets/images/chain.png"
import softwareIcon from "../../../../assets/images/002-software.png"


import { canIUse } from './../../../../utils'
import GlobalStateContext from '../../../../context'
import { STREAM_SOURCE_ENCODER, STREAM_SOURCE_STUDIO ,STREAM_SOURCE_URL} from '../../../../constants'
import { SubscribeButton } from '../../../../components/protected/Subscribe'
import { Icon } from 'antd'




// type Props = {
//   onSelect: Function,
//   stream?: Object
// }
const  SelectStreamSource = forwardRef((props,ref) =>{


  const history = useHistory();
  const [state, dispatch] = useContext(GlobalStateContext)
  const { io, features_accessible, active_streams } = state;
  const [selected, setselected] = useState(props.stream?.source)
  const [canStudio, setCanStudio] = useState()
  const [canRTMP, setCanRTMP] = useState()




  useEffect(() => {
    setCanStudio(canIUse("webcam", features_accessible))
    setCanRTMP(canIUse("custom_rtmp_input", features_accessible))
    // console.log(props)
  }, [])

  useEffect(() => {
   
    setselected(props.stream?.source)
  }, [props.stream])



  const onSelect = (data) => {
    setselected(data);
    props.onSelect(data);
   

  }
  return (

    <div className=" text-center">
      <div className='flex flex-wrap '>
        {/* <FeatureCard image={webcam} title={"Webcam"} description="Eg. phone camera" onClick={props.onSelect.bind({}, WebMediaStream)} /> */}
        <FeatureCard className='border-blue-500  hover:border-blue-300 px-3 py-3  ' 
        selected={selected == STREAM_SOURCE_ENCODER} disable={!canRTMP} image={softwareIcon} 
        title={"Software"} description="Eg. OBS, vMix, Zoom" onClick={() => onSelect(STREAM_SOURCE_ENCODER)} />
      
        <FeatureCard className='hover:bg-gray-100 hover:outline  px-3 py-3 ' selected={selected == STREAM_SOURCE_STUDIO} 
          disable={!canStudio} image={webcam} 
          title={<>Video Studio </>}
          description={<><div>Eg. phone camera</div> {!webcam && <SubscribeButton />}</>}
          onClick={() => onSelect(STREAM_SOURCE_STUDIO)} />
        <FeatureCard className='hover:bg-gray-100 hover:outline  px-3 py-3 ' selected={selected == STREAM_SOURCE_URL} 
          disable={!canStudio} image={link} 
          title={<> External Link </>}
          description={<><div>Eg. youtube video, facebook</div> <p> <Icon type='info-circle' ></Icon> restream not supported yet</p> </>}
          onClick={() => onSelect(STREAM_SOURCE_URL)} />
      </div>
    </div>
  )
});

export default SelectStreamSource;