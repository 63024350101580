import React, { useContext } from 'react';
import GlobalStateContext from '../../context';
import bgRect from "../../assets/images/dotted_rectangle.svg";


export default function Terms() {

    const [state,] = useContext(GlobalStateContext)
    const { configuration: { app_name } } = state
    return (
        <section className="section terms">
            <div className="container m-t-20  m-b-20" style={{ maxWidth: "800px" }}>
                <svg  style={{left:'80%'}} className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384"><defs><pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse"><rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor"></rect></pattern></defs><rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"></rect></svg>
                <h1 className="text-center">Terms of Use</h1>
                <div className="terms">

                    <div className="term">
                        <h2>Your Agreement</h2>
                        <p>
                            Welcome to the multistreaming platform operated by {app_name}, Inc. ( "{app_name}") consisting of the web site available at the URL https://{app_name}.com and all related services, software applications and networks that allow for the authorized streaming and distribution content over the internet (the "{app_name} Service").

                            You affirm that you are either more than 18 years of age, or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms of Service, and to abide by and comply with these Terms of Service. In any case, you affirm that you are over the age of 16, as the {app_name} is not intended for children under 16. If you are under 16 years of age, then please do not use the {app_name} Service.

                            You agree to be bound by the terms of service of media or social network platforms by connecting your media or social network platforms accounts to the {app_name} Service (for example: you agree to YouTube’s Terms of Service by connecting your YouTube account to {app_name}).

                        </p>
                        <p>

                            PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY REGISTERING FOR, ACCESSING, BROWSING, DOWNLOADING FROM OR USING THE {app_name} SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS OF SERVICE. IF AT ANY TIME YOU DO NOT AGREE TO THESE TERMS OF SERVICE, PLEASE IMMEDIATELY TERMINATE YOUR USE OF THE {app_name} SERVICE OR, IF YOU ARE A CONSUMER RESIDENT IN THE EUROPEAN UNION, CONTACT US AT TEAM@{app_name}.COM TO DISCUSS ANY CONCERNS YOU MAY HAVE REGARDING THESE TERMS OF SERVICE.

IF YOU ARE USING OR OPENING AN ACCOUNT WITH {app_name} ON BEHALF OF A COMPANY, ENTITY, OR ORGANIZATION (COLLECTIVELY, A " SUBSCRIBING ORGANIZATION ") THEN YOU REPRESENT AND WARRANT THAT YOU:
 <ol>
                                <li> ARE AN AUTHORIZED REPRESENTATIVE OF THAT SUBSCRIBING ORGANIZATION WITH THE AUTHORITY TO BIND SUCH ORGANIZATION TO THESE TERMS OF SERVICE; </li>
                                <li> HAVE READ THE FOREGOING TERMS; </li>
                                <li> UNDERSTAND THESE TERMS OF SERVICE, AND </li>
                                <li> AGREE TO THESE TERMS OF SERVICE ON BEHALF OF SUCH SUBSCRIBING ORGANIZATION.</li>
                            </ol>

BY USING OR OPENING AN ACCOUNT WITH {app_name}, YOU ACKNOWLEDGE AND AGREE FOR THE {app_name} SERVICE TO PERFORM ACTIONS (SUCH AS, BUT NOT LIMITED TO: "CONNECTING", "SUBSCRIBING", "FOLLOWING", OR "LIKING") ON YOUR BEHALF ONLY TO OR ON THE OFFICIAL {app_name} ACCOUNT(S) ON THE PLATFORM(S) YOU EXPLICITLY CONNECT.

</p>
                    </div>

                    <div className="term">
                        <h2>Privacy Policy</h2>
                        <p>Please see our <a href="/privacy"> Privacy Policy</a> for information relating to how we collect, use, and disclose your personal information.</p>
                    </div>
                    <div className="term">
                        <h2>Modification of these Terms of Use</h2>
                        <p>
                            {app_name} reserves the right, at our discretion, to change, modify, add, or remove portions of these Terms of Use at any time
                            (for example to reflect updates to the {app_name} Service or to reflect changes in the law). If {app_name} changes these Terms of
                            Use, we will provide you notice of these changes, such as by sending an email, posting a notice on the {app_name} Service.
                            Please check these Terms of Use and any Guidelines periodically for those changes. Your continued use of the {app_name} Service
                            after the posting of changes constitutes your binding acceptance of such changes. For any material changes to these {app_name}
                            of Service, the amended terms will automatically be effective thirty days after they are initially posted on the {app_name}
                            Service unless you contact us to discuss any questions or comments during that time period. We will always make a reasonable
                            effort to notify you if we do change these Terms of Use.
                </p>
                    </div>

                    <div className="term">
                        <h2>Streamers Content Representations and Warranties</h2>
                        <p>
                            You are solely responsible for your Broadcaster Content and the consequences of posting or publishing it. By uploading and publishing your Broadcaster Content, you represent, and warrant that: (1) you are the creator and owner of the Broadcaster Content or otherwise have sufficient rights and authority to grant the rights granted herein; (2) your Broadcaster Content does not and will not (a) infringe, violate, or misappropriate any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right or (b) slander, defame, or libel any other person; (3) your Broadcaster Content does not contain any viruses, adware, spyware, worms, or other harmful or malicious code or (4) unless you have received prior written authorization, your Broadcaster Content specifically does not contain any prerelease or non-public beta software or game content or any confidential information of {app_name} or third parties. {app_name} reserves all rights and remedies against any Broadcasters who breach these representations and warranties. Content is Uploaded at Your Own Risk.

                            {app_name} uses reasonable security measures in order to attempt to protect Broadcaster Content. However {app_name} cannot guarantee that there will be no unauthorized copying or distribution of Broadcaster Content nor will {app_name} be liable for any copying or usage of the Broadcaster Content not authorized by Resteam. You hereby release and forever waive any claims you may have against {app_name} for any such unauthorized copying or usage of the Broadcaster Content, under any theory.

                            THE SECURITY MEASURES TO PROTECT BROADCASTER CONTENT USED BY {app_name} HEREIN ARE PROVIDED AND USED "AS-IS" AND WITH NO WARRANTIES OR ASSURANCES THAT SUCH SECURITY MEASURES WILL WITHSTAND ATTEMPTS TO EVADE SECURITY MECHANISMS OR THAT THERE WILL BE NO CRACKS, DISABLEMENTS OR OTHER CIRCUMVENTION OF SUCH SECURITY MEASURES.
</p>
                    </div>


                    <div className="term">
                        <h2>Agreement to Terms</h2>
                        <p>
                            By creating an account with {app_name}, paid or otherwise, you agree to these terms below. If notified of any changes to terms, you confirm your agreement to the new terms by continuing to use your {app_name} account.
                            Also note that by using this service, you agree to the terms and conditions as well as the privacy policies of the corresponding media or social network platform:
                            <li>YouTube - <a href="https://www.youtube.com/t/terms"> Youtube Terms of Service</a>, <a href="http://www.google.com/policies/privacy"> Google Privacy Policy</a>,<a href="https://myaccount.google.com/permissions?pli=1"> Revoking access</a></li>
                            <li>Twitch - <a href="https://www.twitch.tv/p/legal/terms-of-service/"> Twitch Terms of Service</a> , <a href="https://www.twitch.tv/p/legal/privacy-policy"> Twitch Privacy Policy</a></li>
                            <li>Twitter/Periscope - <a href="https://twitter.com/en/tos"> Periscope Terms of Service</a>, <a href="https://twitter.com/en/privacy"> Periscope Privacy Policy</a></li>
                            The list above is for your reference only, if you cannot find the media or social network platform you have connected to {app_name} in this list, please visit their website for the Terms of use and Privacy Policy.
</p>
                        <h2>Billing</h2>
                        <p>
                            {app_name} and Scheduler are separate services. You may manage each service individually, and you will be charged separately for each.

                            You may at any time upgrade your {app_name} account, your Scheduler account, or both, including converting the status from a free account into a paid account. We’ll automatically bill you from the date of your upgrade, and upon each periodic renewal until cancellation.

                            Your subscription begins as soon as your initial payment is processed. Your subscription will automatically renew each month without notice until you cancel. You authorize us to store your payment method(s) and to automatically charge your payment method(s) every month until you cancel. We will automatically charge you the then-current rate for your plan, plus applicable taxes (such as VAT or GST if the rate does not include it), every month upon renewal until you cancel.

                            Please be aware that your payment date may change under certain circumstances (for example, a failed payment attempt). If in doubt, you may confirm your next payment due date by checking your account. The length of your billing cycle will depend on the type of subscription that you choose when you sign up for the service.

                            We may change your plan’s rate each monthly renewal term, and we will notify you of any rate change with the option to cancel. If the applicable VAT or GST rate (or other included tax or duty) changes during your one-month term, we will accordingly adjust the tax-inclusive price for your plan on your next billing date.

                            {app_name} is not responsible for any bank fees, commissions or overdrafts. Please contact your financial institution directly with any questions or concerns about these matters. For European Economic Area customers, your bank may require you to authenticate your initial purchase using a password, a one-time code sent to your mobile number, or biometric recognition. When you authenticate, you also authorize us to charge your payment method for your additional purchases without providing us further payment information or other instructions (i.e., we will initiate future payments independently). Such additional purchases may occur when we automatically charge your payment method in connection with a recurring subscription or when you change your {app_name} account, your Scheduler account, or both.

                            If all payment methods in your account fail, we may suspend your account until you either renew a subscription or cancel it. You can edit your payment information anytime in your Account Billing page.
</p>
                    </div>


                    <div className="term">
                        <h2>Cancellation/Refunds</h2>
                        <p>
                            You can cancel your subscription anytime, and no additional payments will be required. If you cancel within 7 days of your initial order, you’ll be fully refunded, all features and benefits of your account provided with a paid account will be removed, and your service will be converted to a free account. Please contact customer support via chat to get a refund. Should you cancel after 7 days, your payment is non-refundable, and your paid account service will continue until the end of that month’s billing period.

                            In addition, no prorated refunds or credits will be available for subscriptions cancelled in the middle of a billing period. You will continue to have access to your paid account until the end of your current billing period, but it will automatically close at the end of that billing period.

                            Please also note:


                            Only first-time subscribers are eligible for the 7-day refund option.
                            If you upgrade from one paid plan to another, the new upgrade is not considered an “initial purchase", and won't be refunded unless the upgrade is cancelled within the 7 day trial period of the original purchase.
                            The 7 day refund policy is applicable for both {app_name} and Scheduler. Refunds must be requested for each of these programs independently.
                            {app_name}’s policies described here apply identically whether an account is simply cancelled, or cancelled and deleted.
                            While unusual circumstances may be considered on a case-by-case basis, {app_name} reserves the right to determine resolution and provide refunds and/or credits at our sole discretion, or as required by law.
</p>
                    </div>

                    <div className="term">
                        <h2>Suspensions / Downgrades</h2>
                        <p>
                            Your paid account will remain in effect until it's downgraded, cancelled or terminated. If you don’t pay for your paid account in a timely manner, we reserve the right to suspend it or downgrade it to a free account without notice.
</p>
                    </div>

                    <div className="term">
                        <h2>Termination</h2>
                        <p>
                            {app_name} reserves the right, without notice and in our sole discretion, to terminate your license to use the {app_name} Service (including to post Broadcaster Content), and to block or prevent your future access to and use of the {app_name} Service. Your only remedy with respect to any dissatisfaction with (1) the {app_name} Service, (2) any term of these Terms of Service, (3) any policy or practice of {app_name} in operating the {app_name} Service, or (4) any content or information transmitted through the {app_name} Service, is to terminate your account and discontinuing use of any and all parts of the {app_name} Service. In serious cases of breach of these Terms of Service (for example, infringement of {app_name}’s intellectual property rights or actions causing it financial loss), {app_name} may consider it appropriate to take legal action against you (though we will try to resolve matters amicably with you if possible).
</p>
                    </div>

                    <div className="term">
                        <h2>Your Warranty and Indemnification Obligations</h2>
                        <p>
                            Warranty. By uploading your content to the Services, you agree that you have: (A) all necessary licenses and permissions to use and Share your content; and (B) the rights necessary to grant the licenses in the Terms.
                            Indemnification. You will indemnify us and our subsidiaries, affiliates, officers, agents, employees, partners, and licensors from any claim, demand, loss, or damage, including reasonable attorneys’ fees, arising out of or related to your content, your use of the Services, or your violation of the Terms. We have the right to control the defense of any claim, action, or matter subject to indemnification by you with counsel of our own choosing. You will fully cooperate with us in the defense of any such claim, action, or matter.
</p>
                    </div>

                    <div className="term">
                        <h2>Disclaimers of Warranties</h2>
                        <p>
                            The Services are provided “AS-IS.” To the maximum extent permitted by law, we disclaim all warranties, express or implied, including the implied warranties of non-infringement, merchantability, and fitness for a particular purpose. We make no commitments about the content within the Services. We further disclaim any warranty that (A) the Services will meet your requirements or will be constantly available, uninterrupted, timely, secure, or error-free; (B) the results obtained from the use of the Services will be effective, accurate, or reliable; (C) the quality of the Services will meet your expectations; or (D) any errors or defects in the Services will be corrected.
                            We specifically disclaim all liability for any actions resulting from your use of any Services. You may use and access the Services at your own discretion and risk, and you are solely responsible for any damage to your computer system or loss of data that results from the use of and access to any Service.
                            If you post your content on our servers to publicly share through the Services, we are not responsible for: (A) any loss, corruption, or damage to your content (B) the deletion of Content by anyone other than {app_name}; or (C) the inclusion of your Content by third parties on other websites or in other media.
</p>
                    </div>


                    <div className="term">
                        <h2>Limitation of Liability</h2>
                        <p>
                            IN NO EVENT SHALL {app_name}, ITS OWNERS AND/OR EMPLOYEES BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF {app_name} SERVICE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM {app_name} SERVICE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH OUR WEBSITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE {app_name}, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. YOU SPECIFICALLY ACKNOWLEDGE THAT {app_name} SHALL NOT BE LIABLE FOR USER SUBMISSIONS OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
</p>
                    </div>

                </div>
            </div>
            <svg style={{right:"80%",top:"50%"}}  className="absolute top-12 right-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384"><defs><pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse"><rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor"></rect></pattern></defs><rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"></rect></svg>

        </section>

    )
}
