import React, { useContext } from 'react'
import GlobalStateContext from '../../context'
import CookieConsent from 'react-cookie-consent';
import {Divider, Icon} from 'antd';
import logo  from "../../assets/images/logo_transparent_cut_landscape.png";
import { Link, useLocation } from 'react-router-dom';
import IconFont from "../IconFont";
import moment from "moment";

export default function ShortFooter(props) {
    const [state] = useContext(GlobalStateContext);
    const { configuration:{app_name} } = state
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    return (
        !queryParams.get("hideFooter") ?
        <section className="footer-short p-3 text-center bg-white">
            <div className='row'>
                <div className="socials col-md-4 col-sm-12">
                    <a href="https://twitter.com/viblica"> <IconFont type="icon-twitter"/> </a>
                    <a href="https://facebook.com/viblica"> <IconFont type="icon-facebook"/> </a>
                    <a href="https://facebook.com/viblica"> <Icon type="icon-google"/> </a>
                </div>
                <div className="crights col-md-4 col-sm-12"> 	&copy; {moment().year()} {app_name}. All Rights Reserved</div>

                <div className="links-container col-md-4 col-sm-12">
                    <ul className="links">
                        <li>
                            <Link to="/"> Home </Link>
                        </li>
                        <li>
                            <Link to="/app"> Application </Link>
                        </li>
                        <li>
                            <Link to="/pricing"> Pricing </Link>
                        </li>
                        <li>
                            <Link to="/terms"> Terms </Link>
                        </li>
                        <li>
                            <Link to="/privacy"> Privacy </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <CookieConsent
                buttonClasses={"btn btn-success"}
                // location="top"
                buttonText="Yes, I Accept"
                cookieName="cookieConsent"
                // style={{ background: "rgb(43, 134, 125)" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
                contentClasses="text-center"
                contentStyle={{    placeItems: "center",
                    display: "grid"}}
            >
                <div className="p-3 text-center" style={{maxWidth:"700px"}}>
            
                {app_name} uses cookies that helps the website function and to help you understand how to interact with it. We use these cookies to provide you with  improved and customized user experience.
                If you continue to the use of this website, we assume you agree to it as well as our
                <a href='/terms'> terms </a>  and <a href='/privacy'> privacy policy</a>.
                </div>
               
            </CookieConsent>
        </section>:
        <></>
    )
}
