
import React, { useContext } from 'react'
import GlobalStateContext from '../../../../context';

export default function StreamFeaturesAccessible() {
    const [state, dispatch] = useContext(GlobalStateContext);
    const {features_accessible} =state
  return (
    <div className='mt-2 mb-2'>
        {/* <Tag > <strong> Max Time </strong> : {features_accessible?.max_time < 0 ? "Unlimited" : ms(features_accessible?.max_time)}</Tag>
        <Tag > <strong> Max Destination </strong> : {features_accessible?.max_destinations < 0 ? " Unlimited" : features_accessible?.max_destinations}</Tag> */}
    </div>
  )
}
