import React from 'react'
import styled from  'styled-components'
import styles from './banner_breakingnews.scss'
export default function BreakingNews(props) {


    const Title = styled.span`
    
        background-color: ${props?.style?.title?.background };
        display: block;
        height: 100%;  
        width: 90px;
        font-family: arial;
        font-size: 11px;
        position: absolute; 
        top: 0px;
        margin-top: 0px;
        margin-left: 20px;
        padding-top: 7px;
        padding-left: 10px;
        z-index: 3;
        &:before {
          content:"";
          position: absolute;
          display: block;
          width: 0px;
          height: 0px;
          top: 0;
          left: -12px;
          border-left:12px solid transparent;
          border-right: 0px solid transparent;
          border-bottom: 30px solid ${props?.style?.title?.background};
        }
        &:after {
          content:"";
          position: absolute;
          display: block;
          width: 0px;
          height: 0px;
          right: -12px;
          top: 0;
          border-right:12px solid transparent;
          border-left: 0px solid transparent;
          border-top: 30px solid ${props?.style?.title?.background };
        }
      
    
    `

 
    
    
    return (
        <div className='banner m-0'>

        <div id="breaking-news-container">
            <div id="breaking-news-colour" className="slideup animated">

            </div>
            <Title className="delay-animated slidein" >
            {props?.title ?? '// BREAKING //'}
            </Title>
            <a className="breaking-news-headline delay-animated2 fadein marquee text-white">
               { props?.text ?? "This could be used to announce a breaking news"}
            </a>
        </div>
        </div>
    )
}
