
import Webservice from './WebService';



export default class MediapanelWebService extends Webservice {

    async getDefault(payload) {

        try {
            return await this.get({ endpoint: 'mediapanels/default', data: payload })
        } catch (error) {
            throw error;
        }
    }
    async getMediapanels(payload) {

        try {
            return await this.get({ endpoint: 'mediapanels', data: payload })
        } catch (error) {
            throw error;
        }
    }



    /**
     * Saves Mediapanels info
     * @param {*} param0 
     */
    async save(data ) {

        try {
    
            await this.post({ endpoint: `mediapanels`, data })
             
        } catch (error) {
            throw error;
        }
    }


    async getMediapanel(id) {
        try {
            return await this.get({ endpoint: `mediapanels/${id}` });
        } catch (error) {
            throw error;
        }
    }
 
  


    async deleteMediapanel(id) {
        try {
            return await this.delete({ endpoint: `mediapanels/${id}` });
        } catch (error) {
            throw error;
        }
    }

}