import Webservice from './WebService';



export default class PaymentWebService extends Webservice {

    async getTransactions() {
        try {
            return await this.get({ endpoint: 'payments', data: {} })
        } catch (error) {
            throw error;
        }
    }

    async pay(data) {
        try {
            return await this.post({ endpoint: data?.unauth_id ? `payments/pay/unauth` : `payments/pay`, data })
        } catch (error) {
            throw error;
        }
    }
 

    async getTransaction(id) {
        try {
            return  await this.get({ endpoint: `payments/${id}`}); 
        } catch (error) {
            throw error;
        }
    }


    async deleteTransaction(id) {
        try {
            return  await this.delete({ endpoint: `payments/${id}`}); 
        } catch (error) {
            throw error;
        }
    }


    /**
     * 
     * @param {entity:String,amount:Number} data 
     */

    async confirm(data){

        try {
            return data.user_id?  await this.get({ endpoint: 'payments/confirm/unauth', data }):
            await this.get({ endpoint: 'payments/confirm', data })
        } catch (error) {
            throw error;
        }

    }

}