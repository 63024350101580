import React, { useContext, useEffect } from 'react'
import GlobalStateContext from '../context';
import useGlobalState from '../store';
import { SET_ITEM } from '../store/actions/types';
import { useQuery } from '../utils';

export default function RefererChecker() {
    const [state,dispatch] = useContext(GlobalStateContext );
    const query = useQuery();
   
    useEffect(() => {
        let ref= query.get("ref");

        if(ref){
            console.log(query.get("ref"),"query");
            dispatch({
                type: SET_ITEM,
                payload:['referer',ref]
            })
        }
       
    }, [])

    return (
        <div>
            
        </div>
    )
}
