import React, { Suspense, useEffect, lazy, useState } from 'react';
import useGlobalState from "./store"
import GlobalStateContext from './context';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ProtectedPageIndex from './pages/protected/Index';
import ProtectedRoute from './routers/ProtectedRoute';

import AuthService from './classes/AuthService';
import { SET_ACTIVE_USER, SAVE_ITEM, SET_ITEM, LOGOUT } from './store/actions/types';
import ConfigurationWebService from './classes/ConfigurationWebService';
import StreamWebService from './classes/StreamWebService';
import PackageWebService from './classes/PackageWebService';
import { protectedRoutesPrefix } from './routers/routes';
import { ipLookUp, pushNotificationPrompt } from './utils';

import publicIp from "public-ip";
import process from "process";

import Loading from './components/Loading';
import GoogleAnalytics from './components/GoogleAnalytics';
import ScrollIntoView from "./components/ScrollIntoView";
import EmbededVideo from './components/EmbededVideo';
import UpdatePrompt from './pages/protected/streams/components/UpdatePrompt';
import RefererChecker from './components/RefererChecker';

import './assets/style/style.scss';
import './index.css';
import Cookie from "js-cookie"

console.log = (process.env.NODE_ENV === 'production') ? () => { } : console.log

const Home = lazy(() => import('./pages/front/Home.js'));
const MaintenanceMode = lazy(() => import('./components/MaintenanceMode'));



function App() {
  const [state, dispatch] = useGlobalState();
  const { user} = state;
  const token = localStorage.getItem('token');
  const [ready, setReady] = useState(false);
  const [promptUpdate, setUpdatePrompt] = useState(false);


  const fetchConfigs = async () => {
    const localVersion = localStorage.getItem("version") ;
    const webservice = new ConfigurationWebService()
    const values = await webservice.getConfigValues().then(res => res.data)
    dispatch({
      type: SAVE_ITEM,
      payload: ['configuration', values]
    })

    return values
  }
  const setCloudFrontCookies = async () => {
    
    const webservice = new AuthService()
    const cookies = await webservice.getSignedCloudfrontCookie().then(res => res.data);

    // for(var cookie in cookies){
    //   console.log("xxxxxxz",cookie)
    //   Cookie.set(cookie,cookies[cookie],{domain:"d20d8n7x1p1yh0.cloudfront.net"})

    // }
    
    return cookies
  }
  const fetchStreamTypes = async () => {
    const webservice = new StreamWebService()
    const values = await webservice.getStreamsTypes().then(res => res.data)
    console.log("xxxxxxz",values)
    dispatch({
      type: SET_ITEM,
      payload: ['stream_types', values]
    })
    return values
  }
  

  const fetchStreamDestinations = async () => {
    const webservice = new StreamWebService()
    const values = await webservice.getDestinations().then(res => res.data)
    dispatch({
      type: SET_ITEM,
      payload: ['stream_destinations', values]
    })
    return values
  }

  const fetchUserLocationDetails = async () => {
    //get user country code

    await publicIp.v4().then(async ip => {
      await ipLookUp(ip).then(resp => {

        const data = resp.data;

        dispatch({
          type: SET_ITEM,
          payload: ['userLocationData', data]
        })

        if (!!data.exchange_rate_USD) { // if the exchange rate is not defined then we maintain fallback currency and rate
          dispatch({
            type: SET_ITEM,
            payload: ['currency', data.currency]
          })
          dispatch({
            type: SET_ITEM,
            payload: ['xrate', data.exchange_rate_USD]
          })
        }
      })

    }
    ).catch(error => {

    })
  }

  const fetchPackages = async () => {
    const webservice = new PackageWebService()
    const values = await webservice.getPackages().then(res => res.data.entries)
    const _features = await webservice.getFeatureTypes().then(res => res.data)

    dispatch({
      type: SET_ITEM,
      payload: ['packages', values]
    })
    dispatch({
      type: SET_ITEM,
      payload: ['feature_types', _features]
    })
    return values
  }


  const initializeApp = async () => {
    //check store if user data is set
    if (!user) {
      // if not check localstorage for token
      //if not log user out
      if (token) {
        //request for user info
        await (new AuthService()).getUserProfile()
          .then(res => res.data)
          .then(user => {
            dispatch(
              {
                type: SET_ACTIVE_USER,
                payload: user
              }
            )
          }).catch(err => {
            dispatch(
              {
                type: LOGOUT
              }
            );
            setReady(true);
          });
      } else {
        setReady(true); //theres not token so we can proceed. but if there is then useEffect will kick in after user info has been retrieved
      }

    } else {
      if (user.active_subscription) {
        const { features } = state.user.active_subscription;
        dispatch(
          {
            type: SET_ITEM,
            payload: ['features_accessible', features]
          }
        )
      }

      //ask User to subscribe to push
      // pushNotificationPrompt(user)
      setReady(true);
    }
    fetchStreamTypes();
    fetchStreamDestinations()
    fetchPackages()
    fetchConfigs();
    fetchUserLocationDetails();
    setCloudFrontCookies()
    //@TODO register io events
    // if(!!state.user){
    //   console.log("I am in");
    //   state.io.on(`profile_update_${state.user.id}`,ioProfileUpdate);
    // }
    return;
  }

  useEffect(() => {
    console.log("Initializing App");
    initializeApp();
  }, [state.user]);

  return (
    <Suspense fallback={<Loading />}>
      {
        !ready ? <Loading /> : (
          <GlobalStateContext.Provider value={[state, dispatch]} >
          

            <div className="App">
              {state.configuration.maintenance_mode === '1' ?
                <MaintenanceMode /> :
                <Router >
                  <ScrollIntoView>
                    <UpdatePrompt/>
                    <RefererChecker/>
                    {/* <TestMode /> */}
                    {/* <GoogleAnalytics> */}
                      <Switch>
                        <ProtectedRoute path={`/${protectedRoutesPrefix}`}>
                        
                          <ProtectedPageIndex />
                        
                        </ProtectedRoute>
                        <Route path="/embed/:id"  component={EmbededVideo}/>
                        <Home />
                      </Switch>
                    {/* </GoogleAnalytics> */}
                  </ScrollIntoView>
                </Router>
              }
            </div>

          </GlobalStateContext.Provider>


        )
      }
    </Suspense>
  );
}

export default App;


