import Webservice from './WebService';


export default class DestinationWebservice extends Webservice {

    async fetchDestinations() {

        try {
            return await this.get({ endpoint: `destinations` })
        } catch (error) {
            throw error;
        }
    }

    async addDestination(payload = {
        label: String, image_url: String,
        manual_entry: String,
        platform: String,
        data: {
            token: String,
            server: String
        }
    }) {
        try {
            return await this.post({ endpoint: `destinations`, data: payload })
        } catch (error) {
            throw error;
        }
    }
    async deleteDestination(destiinationId, payload) {
        try {
            return await this.delete({ endpoint: `destinations/${destiinationId}`, data: payload })
        } catch (error) {
            throw error;
        }
    }


}