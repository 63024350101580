import React, { useEffect } from 'react'
import { useContext } from 'react'
import GlobalStateContext from '../../../../context'
import MediaPannelBanner from './mediaPannelBanners/MediaPannelBanner'




export function MediaPanelOverlayBanner({ banner }) {
  return (
    <div className={`banner ${banner.scroll ? 'scrollable' : ''}`}>
      {!!banner.title && <div className='banner-title'>{banner.title}</div>}
      {banner.scroll ?
        <div className={`banner-description scrolling-limit`} >
          <div className='scrolling'>{banner.text}</div>
        </div>
        :
        <div className={`banner-description`} >{banner.text}</div>}
    </div>
  )
}


export default function MediaPanelOverlay({ mediaPanel }) {


  const [gState, dispatch] = useContext(GlobalStateContext)
  let banners = [];
  let branding = {}
  if (!!mediaPanel) {
    banners = mediaPanel.banner?.active || []
    branding = mediaPanel?.branding
  }

  useEffect(() => {
    console.log("Nigga", mediaPanel)


  }, [mediaPanel])

  return (
    <div className='mediaPanelOverlay'>
      <div className="branding">
        <div className='branding-logo'>
          <img src={branding?.logo} />
        </div>
      </div>

      <div className='banners'>
        {

          banners && banners.map((item,index) => {
            return <MediaPannelBanner key={index}  banner={item} />
          })
        }

      </div>
    </div>
  )
}
