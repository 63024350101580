import { Button, Icon } from 'antd'
import { OpenVidu } from 'openvidu-browser'
import React, { useContext, useEffect, useRef, useState } from 'react'
import AudioVisualizer from '../pages/protected/streams/components/AudioVisualizerSmall'
import AudioVisualizerSmall from '../pages/protected/streams/components/AudioVisualizerSmall'
import cameraLens from "../assets/images/camera-lens.png";
import allowCamMic from "../assets/images/allow_cam_mic.png";
import GlobalStateContext from '../context'
import { SAVE_ITEM } from '../store/actions/types'
import { useIsMounted } from '../utils'
import { isEmpty } from 'lodash'
import { Image } from '@material-ui/icons'

export default function MediaConfig(props) {


    const [devices, setDevices] = useState({ "audioinput": [], "videoinput": [] })
    const [mediaStream, setMediastream] = useState(null)
    const [mediaDevicesAllowed, setMediaDevicesAllowed] = useState(false)
    const videoPreviewRef = useRef()
    const [mediaSourceKey, setMediaSourceKey] = useState(0)
    const [state, dispatch] = useContext(GlobalStateContext)
    const isMounted = useIsMounted();
    const [selected, setSelected] = useState({ ...state.streamMediaConfig })

    const [isLoading, setisLoading] = useState(true)
    var OV = new OpenVidu();



    const cleanUp = () => {

        if (mediaStream) {

            const tracks = mediaStream.getTracks()
            // console.log("Cleaning", tracks)
            if (tracks) {
                tracks.forEach(track => {

                    // console.log("Cleaning", track)
                    track.stop()
                });
            }

        }
    }

    const onSelected = async (properties = null) => {

        try {
            properties = properties || {
                audioinput: selected?.audioinput,
                videoinput: selected?.videoinput,
                // resolution: '1280x720',
                // frameRate: FRAME_RATE
            }

            let _mediaStream = await OV.getUserMedia({ audioSource: properties.audioinput, videoSource: properties.videoinput })

            console.log("cat", properties, devices);

            setMediastream(_mediaStream)


            //update global config
            dispatch({
                type: SAVE_ITEM,
                payload: ['streamMediaConfig', { ...state.streamMediaConfig, ...properties, permission: true }]
            })

            setMediaDevicesAllowed(true)
            

        } catch (error) {
            console.log("cate", error)
            if (error.name === "DEVICE_ACCESS_DENIED") {
                setMediaDevicesAllowed(false)
                dispatch({
                    type: SAVE_ITEM,
                    payload: ['streamMediaConfig', { ...state.streamMediaConfig, ...properties, permission: false }]
                })

            }
        }finally{
            setisLoading(false);
        }
    }
    const onDeviceChange = (event, type) => {
        const value = event.target.value;

        setSelected({
            ...selected,
            [type]: value
        })

    }



    const getDevices = () => {
        console.log("Getting devices...")
        const _devices = { "audioinput": [], "videoinput": [] };

        OV.getDevices().then(devices => {

            // console.log("devices", devices);
            // 'devices' array contains all available media devices
            for (let device of devices) {
                _devices[device.kind].push(device);

            }
            setDevices(_devices)
        }).catch(err => {
            setMediaDevicesAllowed(false)
            console.log(err)
        });

        // console.log("devices", _devices)

    }


    useEffect(() => {
        if (videoPreviewRef.current) {


            videoPreviewRef.current.srcObject = mediaStream

        }

        return () => {
            cleanUp()
        }
    }, [mediaStream])

    useEffect(() => {

        const res = selected
        console.log("sel", res)
        if (isEmpty(res.audioinput)) {

            res["audioinput"] = devices['audioinput'][0]?.deviceId

        }
        if (isEmpty(res.videoinput)) {
            res["videoinput"] = devices['videoinput'][0]?.deviceId
        }


        onSelected(res)
    }, [selected, devices])

    useEffect(() => {
        console.log("cate", videoPreviewRef.current)
        if (mediaDevicesAllowed == true) {

        }
    }, [mediaDevicesAllowed])

    useEffect(() => {
        getDevices()
    }, [])




    return (
        <>
            {isLoading ? <div className='m-auto flex justify-center'>
                <Icon type="loading" /> <span className='px-1'>  {"Setting up studio"} </span>
            </div> :
            <>

              {  mediaDevicesAllowed == false ?
                    <div className=' bg-white mx-auto mx auto text-center '>
                        <h3 className='text-2xl'>Allow Camera/Microphone Access</h3>
                        <p>To proceed, allow access to your video and microphone in your browser.</p>
                        <div className='m-2'>
                            <img src={allowCamMic} className='w-full h-full rounded-lg' />

                        </div>
                        <div className='text-left'>
                            <h3 className='text-lg'>How to Enable Camera and Microphone Access</h3>

                            <ol className='list-decimal flex flex-col pl-4'>
                                <li className='mt-2'>On this page, a pop-up will appear requesting access to your camera and microphone.</li>
                                <li className='mt-2'>Click "Allow" to grant access. <a target='_blank' href="https://youtu.be/g6b50XQxyck" > (Click here to watch video)</a></li>
                                <li className='mt-2'>If you don't see a pop-up, check your browser's address bar for a camera icon, click it, and select "Allow."  <a target='_blank' href="https://youtu.be/a-Vz6BiUWFo" > (Click here to watch video)</a></li>

                            </ol>

                        </div>

                        <button className='shadow-md mt-4 rounded px-3 py-2 bg-yellow-400  hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-opacity-50'
                            onClick={_ => window.location.reload()}
                        > I have alllowed access </button>

                    </div>
                    :
                    <div className="row br-10 p-0 m-0  bg-blue-100" style={{ overflow: "hidden" }}>


                        <>
                            <div className=' col-md-7 col-sm-12 p-0'>
                                <video key={`video_${mediaSourceKey}`} poster={cameraLens} preload='auto' ref={videoPreviewRef} autoPlay className='video' style={{ height: "100%", maxHeight: "240px", background: "currentColor" }} muted />
                            </div>

                            <div className=' col-md-5 col-sm-12 pt-3'>

                                <div className="form-group" >

                                    <label> Video Input</label>
                                    <select id="videoInputDevice" className="bg-blue-200 form-control" onChange={_ => onDeviceChange(_, "videoinput")}>
                                        {devices.videoinput?.map(device =>
                                            <option selected={selected.videoinput == device.deviceId} key={`video_input_option_${device.deviceId}`} value={device.deviceId}
                                            >{device.label}</option>
                                        )}
                                    </select>
                                </div>
                                <div className='form-group mb-0'>
                                    <label>Audio Intput </label>
                                    <select id="audioInputDevice" className="bg-blue-200 form-control" onChange={_ => onDeviceChange(_, "audioinput")}>
                                        {devices.audioinput.map(device =>
                                            <option selected={selected.audioinput == device.deviceId} key={`audio_input_option_${device.deviceId}`} value={device.deviceId}
                                            >{device.label}</option>
                                        )
                                        }
                                    </select>
                                </div>
                                {/* <div className="form-group m-0" >
        <label> Audio  Output </label>
        <select defaultValue={devices.audioinput[0]?.deviceId} id="audioOutputDevice" className="bg-blue-200 form-control" onChange={_ => onDeviceChange(_, "audiooutput")}>
            {devices.audioinput.map(device =>
                <option key={`audio_output_option_${device.deviceId}`} value={device.deviceId}
                >{device.label}</option>
            )
            }
        </select>
    </div> */}

                                <div className=''>
                                    {mediaStream && <AudioVisualizerSmall mediaStream={mediaStream} />}
                                </div>

                            </div>
                        </>



                    </div>}
            </>
            }
        </>


    )
}
