export default {
    app: {
        name: "Vibica",
        version: '0.0.1',
        url:"http://localhost:5003",
        contact: {
            email: "info@viblica.com",
            phone: ""
        },
        api: {
            server: "http://localhost:3000",
            endpoint: "http://localhost:3000/v1",
            baseUrl: "http://localhost:3000/v1/client/",
            baseUrlIntegration: "http://localhost:3000/v1/integration/",
            socket: "http://localhost:3000/",
            logger: "http://localhost:3000",
            streamingServer: {
                host:"localhost",
                webcamTranscoder: "ws://localhost:4000/", //server that turn webcam data to rtmp
                http: "http://localhost:8000/",
                https: "https://localhost:8443/",
                websocket: "ws://localhost:3333/",
                rtmp: "rtmp://localhost:1935/",
                srt: "srt://localhost:9999",
                apps: {
                    live: 'live'
                }
            },
        }
    },
    flutterwave: {
        production: false,
        keys: {
            public: "FLWPUBK_TEST-04f4962c50367258d8fa6fed3aa90311-X"
        }
    },
    paystack: {
        production: false,
        keys: {
            public: "pk_test_1d43840681f6f365ae465676992961b3854e6ac4"
        }
    },
    googleServices: {
        analytics: "",
    },
    facebook: {
        appID: "704368907069771",
        endpoints: {
            publish_stream_detail: "https://graph.facebook.com/v3.3/me/live_videos"
        }
    },
    google: {
        appID: "412931478392-m5q026tgg2phb2aa389931s9klu3ogd2.apps.googleusercontent.com",
    }

}