import React from 'react'
import UpgradeButton from '../../../../components/protected/UpgradeButton'
import { SubscribeButton } from '../../../../components/protected/Subscribe'

export default function FeatureNotAccessible(props) {
  return (
    <div className=' max-w-lg container text-center' style={{marginTop:'50px'}}>
        <div style={{fontSize:"1.8rem", fontWeight:400}}> {props.title ??" Feature Not Accessable"}</div>

        <p className='p-3'>{ props.description ?? "You do not have acceess to this feature. You may have to subscribe to a package"} </p>
        <SubscribeButton/>
    </div>
  )
}
