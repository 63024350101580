import React, { useContext } from 'react';
import GlobalStateContext from '../../context';


export default function PrivacyPolicy() {

    const [state,] = useContext(GlobalStateContext)
    const { configuration: { app_name } } = state
    return (
        <section className="section terms">
            <div className="container ">
                <h1>Privacy Policy</h1>
                <p>
                    Welcome to the web site {app_name  && app_name.toLowerCase()}.com, and any other web sites, applications, or services that link to this Privacy Policy (collectively, the “{app_name} Service"), which sites, applications or services are operated or produced by {app_name}.

                    {app_name} values the privacy of the users, publishers and others who visit and use the {app_name} Service (collectively or individually, "You" or "Users") and wants you to be familiar with how we collect, use and disclose information from and about you.

                    By agreeing to this Privacy Policy in your {app_name} account setup, or by using the {app_name} Service, you expressly consent to the information handling practices described in this notice.

                    This Privacy Policy applies to {app_name} collection and use of your personal information (i.e., information that identifies a specific person, such as full name, address, phone number, or email address). It also describes generally {app_name}’s practices for handling non-personal information (for example, interests, demographics and services usage).
                </p>
                <div className="terms">

                    <div className="term">
                        <h2>The Information {app_name} Collects</h2>
                        <p>
                            User-provided Information: You may provide to {app_name} what is generally called "personal information" in the USA and elsewhere or "personal data" in the European Union (such as your name, email address, postal mailing address, home/mobile telephone number, credit card number and billing information) if you register for a {app_name} Service, or upload or download certain content or products from the {app_name} Service, enter contests or sweepstakes, or otherwise use the features and functionality of the {app_name} service.

                            "Automatically Collected" Information: When you access the {app_name} Service or open one of our HTML emails, we may automatically record certain information from your system by using cookies and other types of tracking tools. This "automatically collected" information may include Internet Protocol address (" IP Address "), a unique user ID, device type, device identifiers, browser types and language, referring and exit pages, platform type, version of software installed, system type, the content and pages that you access on the {app_name}, the number of clicks, the amount of time spent on pages, the dates and times that you visit the {app_name}, and other similar information. Depending on the law of your country of residence, your IP address may legally be considered personal information.

                            Information from Other Sources: We may obtain information, including personal information, from third parties and sources other than the {app_name}, such as advertisers. We also may obtain information, including personal information, from third parties such as social media or social network platforms for which you have explicitly connected to the {app_name} Service (e.g., information from YouTube using YouTube API Services, Twitch, Youtube, Smashcast, Mixer, or any other platform the {app_name} Service connects with). When you access the {app_name} Service through social media or social networks you are authorizing {app_name} to collect, store and use such information and content in accordance with this Privacy Policy. We may also obtain information from third parties such as social media or social network platforms regarding your use of such services, including information about your use of the content you choose to broadcast through the {app_name} Service. Please refer to privacy policy of the social media or social network platforms you use with {app_name} Service (for example to Google Privacy Policy for YouTube). If we combine or associate information from other sources with personal information that we collect through the {app_name} Service, we will treat the combined information as personal information in accordance with this Privacy Policy.
                        </p>
                    </div>
                    <div className="term">
                        <h2>The Way {app_name} Uses Information</h2>
                        <p>
                            If you submit personally identifiable information to us through the {app_name} Services, we use your personal information to operate, maintain and provide to you the general and personalised features and functionality of the {app_name} Service and to process any flagging activity or correspondence you send to us.

                            We do not use your email address or other personally identifiable information to send commercial or marketing messages without your consent or except as part of a specific programme or feature for which you will have the ability to opt in or opt out. However, We may use your email address without further consent for non-marketing or administrative purposes (such as notifying you of major {app_name} Service changes or for customer service purposes).

                            We use both your personally identifiable information and certain non-personally identifiable information (such as anonymous user usage data, cookies, IP addresses, browser type, clickstream data, etc.) to improve the quality of the {app_name} Service and to create new features, promotions, functionality and services by storing, tracking, analysing and processing user preferences and trends, as well as user activity and communications.

                            To facilitate the {app_name} Service, we obtain and use the stream channel name and key from each platform you have connected to the {app_name} Service.

                            We may use your connected account's information and applicable permission to perform actions such as, but not limited to: "connecting", "subscribing", "following", or "liking" only to or on the official {app_name} accounts associated with your connected social media or social network platform.

                            We use cookies and log file information to: monitor aggregate metrics such as total number of visitors, pages viewed, etc.; and (e) track your entries, submissions and status in promotions or other activities.

                            Additional policies. By connecting any account to {app_name} you agree to the terms and conditions as well as the privacy policies of the corresponding media or social network platform:

                            <li>YouTube - <a href="https://www.youtube.com/t/terms"> Youtube Terms of Service</a>, <a href="http://www.google.com/policies/privacy"> Google Privacy Policy</a>,<a href="https://myaccount.google.com/permissions?pli=1"> Revoking access</a></li>
                            <li>Twitch - <a href="https://www.twitch.tv/p/legal/terms-of-service/"> Twitch Terms of Service</a> , <a href="https://www.twitch.tv/p/legal/privacy-policy"> Twitch Privacy Policy</a></li>
                            <li>Twitter/Periscope - <a href="https://twitter.com/en/tos"> Periscope Terms of Service</a>, <a href="https://twitter.com/en/privacy"> Periscope Privacy Policy</a></li>
                            The list above is for your reference only, if you cannot find the media or social network platform you have connected to {app_name} in this list, please visit their website for the Terms of use and Privacy Policy.
                        
                        </p>
                    </div>
                    <div className="term">
                        <h2>When {app_name} Discloses Information</h2>
                        <p>
                            {app_name} does not share your personal information with other organizations for their own marketing or promotional uses without your prior express consent.

                            {app_name} may disclose Automatically Collected and other aggregate non-personal information with interested third parties to assist such parties in understanding the usage and demographic patterns for certain programs, content, services, advertisements, promotions, or other functionality of {app_name}.

                            We may disclose both personal and non-personal information to service providers working on our behalf to: provide web site hosting, maintenance, and security services; fulfill orders; analyze data and create reports; offer certain functionality; and assist {app_name} in improving the {app_name} and creating new services features. We require that these parties process such information in compliance with this Privacy Policy, we authorize them to use the information only for the purposes for which it is provided to them, and we require these parties to use reasonable confidentiality measures.

                            {app_name} may disclose User information if we believe in good faith that such disclosure is necessary to comply with US state and federal laws (such as U.S. Copyright law) or other applicable laws around the world (for example, in the country of your residence); or respond to a court order, judicial or other government subpoena, or warrant in the manner legally required by the requesting entity.

                            {app_name} also reserves the right to disclose User information that we believe, in good faith and after making reasonable inquiries, is appropriate or necessary to take precautions against liability to {app_name}; protect {app_name} from abusive, fraudulent, or unlawful uses; to investigate and defend ourselves against third-party claims or allegations to protect the security or integrity of the {app_name} Service; or to protect the rights, property, or personal safety of {app_name}, our Users, or others. We will notify you of these disclosures if we reasonably think we can do so legally and without harming the purpose of the disclosure.
                        </p>
                    </div>
                    <div className="term">
                        <h2>Your Choice</h2>
                        <p>
                            You may, of course, refuse to submit personally identifiable information through {app_name} service.

                            However {app_name} features may, for authentication purposes, require you to sign up. The privacy notices of those services govern the use of your personal information associated with them.

                            You may update or correct your personal profile information and email preferences at any time by visiting your account profile page.
                        </p>
                    </div>
                    <div className="term">
                        <h2>How {app_name} Uses Cookies</h2>
                        <p>
                            Like many other sites on the Internet, {app_name} uses cookies—small text files containing a string of alphanumeric characters. {app_name} uses cookies and collects IP addresses and other automatically collected information in order to better facilitate the services provided to you, and to collect non-personal aggregate data for marketing and research purposes. If you accept the cookies used on our site, we may be able to identify your system by reading the cookie stored on your system, but we will not be able to identify you unless you’ve entered your personal information.

                            We may also allow third-party companies to serve ads and/or collect certain anonymous information from you when you visit our website or use our services.
                        </p>
                    </div>
                    <div className="term">
                        <h2>Data Security</h2>
                        <p>
                            {app_name} has strict security measures in place to protect the loss, misuse, and alteration of information under our control. We use secure technology, privacy protection controls, and restrictions on employee access in order to safeguard your information. While we take precautions to protect sensitive information that’s transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job, such as billing or customer service, are granted access to personally identifiable information. The computers/serves in which we store personally identifiable information are kept in a secure environment.

                            Please note that while we continually make every effort to implement safeguards to protect your personal information, the very nature of the Internet makes it impossible to guarantee the security of transmitted data. {app_name} makes no warranties or representations as to the security and confidentiality of any data you transmit. Any data you transmit to {app_name} is at your own risk, and we cannot guarantee that information may not be accessed, disclosed, altered, or destroyed by any breach of our physical, technical, or managerial safeguards.
                        </p>
                    </div>
                    <div className="term">
                        <h2>Delete Account Data</h2>
                        <p>
                            If you would like to delete all data associated with your account, mail us with request on team@{app_name && app_name.toLowerCase()}.com. This action is irreversible.
                        </p>
                    </div>

                </div>
            </div>

        </section>

    )
}
