import React from 'react'
import { useContext,useEffect } from 'react';
import GlobalStateContext from '../../../../context';

import { Modal } from 'antd';
import { SAVE_ITEM, UPDATE_APP } from '../../../../store/actions/types';

export default function UpdatePrompt() {

    const [state, dispatch] = useContext(GlobalStateContext);
    const {configuration,show_update_prompt} = state;

    useEffect(() => {
        const localVersion = localStorage.getItem("app_version");
        if(localVersion){
            if(parseFloat(localVersion || 0.00) < parseFloat(configuration.app_version)){
                dispatch({
                  type: SAVE_ITEM,
                  payload: ['show_update_prompt', true]
                })
          
              }

        }else{
            configuration.app_version && localStorage.setItem("app_version",configuration.app_version );
        }
    }, [configuration]);

    useEffect(() => {
        if(state.show_update_prompt){
            Modal.info({
                title: "Update Found",
                content:<>
                <p> A new version of this app has been found. </p>
                </>,
                okText: "Update",
                onOk: () => { 
                    dispatch({
                        type: UPDATE_APP,
                        payload: configuration.app_version
                    })
                   
                },
            });
        }

    }, [show_update_prompt])
    return (
        <div> </div>
    )
}
