import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OvenPlayer from 'ovenplayer';
import ReactPlayer from 'react-player';
import { isSafari } from 'react-device-detect';
import { light } from '@material-ui/core/styles/createPalette';


class Player extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

 

  render() {

    const { height, width, type,light, url, isLive, isMuted, enableStashBuffer, stashInitialSize, hasAudio, hasVideo, handleError, enableWarning, roundEdges, onMetadataArrived, showControls, autoplay, thumbnail } = this.props;

    return (

      <ReactPlayer
        className={`aspect-w-16 aspect-h-9 player-content  ${this.props.className ?? 'bg-black'}   ${roundEdges && "br-10"}  `}
        playsinline
        onError={handleError}
        playing={autoplay}
        controls={showControls}
        onStart={onMetadataArrived}
        width="100%"
        light={light}
        // height="80vh"

        url={this.props.url}
        style={{ maxHeight: "500px", background: "#000" }}
        config={{
          file: {
            forceHLS: isSafari,
            forceVideo: !isSafari,
            hlsVersion: '0.12.4',
            attributes: {
              poster: thumbnail,
              disablePictureInPicture: true
            }
          }
        }}
      />
      // <div className={this.props.className ?? `bg-black   ${this.props.roundEdges && "br-10"}  `}
      // // style={{ backgroundImage: `url(${bg})`,}}
      // >
      //   <div
      //     ref={this.myRef}
      //     style={{ maxHeight: height, height:"100%", width, objectFit: "contain" }}

      //   />
      // </div>
    );
  }
}

Player.propTypes = {
  type: PropTypes.string,
  url: PropTypes.string.isRequired,
  isLive: PropTypes.bool,
  hasAudio: PropTypes.bool,
  hasVideo: PropTypes.bool,
  showControls: PropTypes.bool,
  enableStashBuffer: PropTypes.bool,
  stashInitialSize: PropTypes.number,
  height: PropTypes.string,
  width: PropTypes.string,
  isMuted: PropTypes.bool,
  enableWarning: PropTypes.bool,
  enableError: PropTypes.bool,
  handleError: PropTypes.func,
  onMetadataArrived: PropTypes.func,
  autoplay: PropTypes.bool,
  light: PropTypes.bool
};

Player.defaultProps = {
  type: 'flv',
  isLive: true,
  hasAudio: true,
  hasVideo: true,
  showControls: true,
  enableStashBuffer: true,
  stashInitialSize: 128,
  height: '100%',
  width: '100%',
  isMuted: false,
  handleError: (err) => { console.log(err) },
  enableWarning: false,
  enableError: false,
  roundEdges: true,
  autoplay: true,
  light:false,
  onMetadataArrived: _ => console.log(_),
};

export default Player;