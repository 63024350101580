export const  FETCH_DATA = "FETCH_DATA";
export const  SET_IMAGE_DATA = "SET_IMAGE_DATA";
export const  SET_ACTIVE_USER = "SET_ACTIVE_USER";
export const  FETCH_ADMIN_USERS = "FETCH_ADMIN_USERS";
export const  SET_PREDICTION = "SET_PREDICTION";
export const  SHOW_NEW_DATA_FORM = "SHOW_NEW_DATA_FORM";
export const  SHOW_VIEW_ADMIN_FORM = "SHOW_VIEW_ADMIN_FORM";
export const  SAVE_USER = "SAVE_USER";
export const  SAVE_ITEM = "SAVE_ITEM";
export const  SET_ITEM = "SET_ITEM";
export const  DELETE_STREAM = "DELETE_STREAM";
export const  FETCH_ITEMS = "FETCH_ITEMS";
export const  LOGIN = "LOGIN";
export const  LOGOUT = "LOGOUT";
export const  UPDATE_APP = "UPDATE_APP";

