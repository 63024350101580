
import Webservice from './WebService';



export default class AuthService extends Webservice {

    async login({ email, password, providerObject=null,otp}) {

        try {
            return await this.post({ endpoint: 'auth/login', data: { email, password , providerObject,otp} })
        } catch (error) {
            throw error;
        }
    }


    /**
     * Sends a social  media login request
     * @param {*} param0 
     */
    async loginSocial({ email, password, service, id, access_token ,referer,location}) {

        try {
            return await this.post({ endpoint: `auth/${service}`, data: { email, password ,service, id, access_token,referer,location} })
        } catch (error) {
            throw error;
        }
    }

    async signup({ name,email, password,phone,referer,location }) {

        try {
            return await this.post({ endpoint: 'auth/register', data: {name, email, password,phone,referer,location } })
        } catch (error) {
            throw error;
        }
    }
    async sendPasswordResetCode(email) {

        try {
            return await this.post({ endpoint: 'auth/send-password-reset', data: {email} })
        } catch (error) {
            throw error;
        }
    }

    async resetPassword(email,password,resetToken) {

        try {
            return await this.post({ endpoint: 'auth/reset-password', data: {email,password,resetToken} })
        } catch (error) {
            throw error;
        }
    }

    async sendLoginCode({id,name,location,referer}) {

        try {
            return await this.post({ endpoint: 'auth/send/otp', data: {id,name,location,referer} })
        } catch (error) {
            throw error;
        }
    }
    
    async sendVerificationCode({id,type,location,referer}) {

        try {
            return await this.post({ endpoint: 'auth/sendVerificationCode', data: {id, type,location,referer} })
        } catch (error) {
            throw error;
        }
    }
    
    async confirmVerificationCode(id,type,code) {

        try {
            return await this.post({ endpoint: 'auth/confirmVerificationCode', data: {id, type,code} })
        } catch (error) {
            throw error;
        }
    }


    async logout() {
        try {

        } catch (error) {
            throw error;
        }
    }

    async getUserProfile(){
         try{
             return  await this.get({endpoint:'auth/profile',})
         }catch(error){
            throw error;
         }
    }

    async updateProfile(data){
         try{
             return  await this.put({endpoint:'auth/profile',data})
         }catch(error){
            throw error;
         }
    }
    async getSignedCloudfrontCookie() {
        try {
            return await this.get({ endpoint: `auth/cloudf/sign` });
        } catch (error) {
            throw error;
        }
    }

}