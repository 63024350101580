import React, { useState, useContext, useEffect } from "react";
import { Loader, UserPlus, UserMinus } from "lucide-react";
import GlobalStateContext from "../context";
import ClientWebService from "../classes/ClientWebService";
import { _notification } from "../utils";

const FollowButton = ({ id, showCount = true, stream }) => {
    const [state, dispatch] = useContext(GlobalStateContext);
    const { user } = state;
    const [fLoading, setFLoading] = useState(false);
    const [followers, setFollowers] = useState([]);
    const [canFollow, setCanFollow] = useState(false);

    const getFollowers = async () => {
        const cwebservice = new ClientWebService();
        try {
            const res = await cwebservice.followers(id);
            setFollowers(res.data);
        } catch (error) {
            _notification.error("Failed to fetch followers");
        } finally {
            setFLoading(false);
        }
    };

    useEffect(() => {
        getFollowers();
    }, []);

    useEffect(() => {
        if (state.user) {
            const alreadyAFollower = followers.map(ff => ff.follower === state.user._id);
            setCanFollow(alreadyAFollower.indexOf(true) === -1);
        }
    }, [followers, state.user]);

    const onFollowClick = async () => {
        setFLoading(true);
        const cwebservice = new ClientWebService();
        const payload = { streamId: stream?.id || stream?._id };

        try {
            await cwebservice.follow(id, payload);
            await getFollowers();
        } catch (error) {
            _notification.error("Operation Failed");
        } finally {
            setFLoading(false);
        }
    };

    if (user._id === id) return null;

    return (
        <div className="inline-flex items-center gap-2">
            {fLoading ? (
                <div className="inline-flex items-center justify-center px-4 py-2 bg-gray-100 rounded-lg">
                    <Loader className="w-5 h-5 text-gray-500 animate-spin" />
                </div>
            ) : (
                <>

                    <button
                        onClick={onFollowClick}
                        disabled={!state.user}
                        className={`inline-flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-lg transition-colors ${state.user && !canFollow
                                ? "bg-red-500 text-white hover:bg-red-600"
                                : "bg-blue-500 text-white hover:bg-blue-600"
                            } disabled:opacity-50 disabled:cursor-not-allowed`}
                    >


                        <>
                            {showCount && followers.length.toLocaleString()}

                            {state.user && !canFollow ? <><UserMinus className="w-4 h-4" /> Unsubscribe </> : <><UserPlus className="w-4 h-4" />Subscribe</>}




                        </>

                    </button>
                </>
            )}
        </div>
    );
};

export default FollowButton;