import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import GlobalStateContext from '../context';



export default function ProtectedRoute({ children, ...rest }) {
    const [state] = useContext(GlobalStateContext)
    const emailNotVerified = !state.user.email_verified_at
   
    return (

        <Route

            {...rest}
            render={({ location }) => {
                //Check if user object is set 
               if(!state.user){
                    return  (<Redirect
                        to ={
                            {
                                pathname: "/login",
                                state: { from: location }
                            }
                        }
                    />)
                   
               }
               //is users email verified? if not redirect to verification page
               if(emailNotVerified){
                return  (<Redirect
                    to ={
                        {
                            pathname: "/verification/email",
                            state: { from: location }
                        }
                    }
                />)
               }

                return  children ;
             
            }}
        >
        </Route>
    )
}
