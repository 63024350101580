import {
    SAVE_USER, SET_ACTIVE_USER, SET_IMAGE_DATA,
    SAVE_ITEM, LOGIN, LOGOUT,
    SHOW_NEW_DATA_FORM,
    FETCH_ADMIN_USERS,
    FETCH_ITEMS,
    SET_ITEM,
    DELETE_STREAM,
    UPDATE_APP
} from './actions/types';

let key, value;


const reducer = (state, action) => {
    switch (action.type) {

        case LOGIN:
            const { token, user } = action.payload;

            if (!action.payload.user) {
                return state;
            }

            localStorage.setItem("token", JSON.stringify(token));

            return {
                ...state,
                user,
                authenticated: true
            }


        case LOGOUT:

            localStorage.removeItem("token");
            localStorage.removeItem("token_facebook");
            localStorage.removeItem("token_twitter");
            localStorage.removeItem("token_google");
            localStorage.removeItem("token_github");

            return {
                ...state,
                user: false,
                authenticated: false
            }

        case SET_ACTIVE_USER:

            return {
                ...state,
                user: action.payload
            }

        case FETCH_ADMIN_USERS:

            return {
                ...state,
                users: action.payload
            }
        case FETCH_ITEMS:
            [key, value] = action.payload

            return {
                ...state,
                [key]: value
            }

        case SET_IMAGE_DATA:

            return {
                ...state,
                image_data: action.payload
            }




        case SHOW_NEW_DATA_FORM:

            return {
                ...state,
                showNewDataForm: action.payload
            }

        case SAVE_USER:
            const newset = state.users.push(action.payload);
            return {
                ...state,
                users: newset
            }

        case SAVE_ITEM:

            [key, value] = action.payload;
            // check if value is array then set as array else object
            const newSet = Array.isArray(value) ? [...value, ...state[key]] : value;
            return {
                ...state,
                [key]: newSet
            }

        case SET_ITEM:

            [key, value] = action.payload;

            return {
                ...state,
                [key]: value
            }
        case DELETE_STREAM:
            const { streams } = state;
            const result = streams.filter(item => item.id !== action.payload)

            return {
                ...state,
                streams: result
            }

        case UPDATE_APP:
            localStorage.setItem("app_version", action.payload)
            window.location.reload(true);
            return state;

        default:
            console.log("Default reducer");
            return state
    }
}

export default reducer;