import React from 'react'
import {Route,Switch} from "react-router-dom";
import ProtectedPagesLayout from '../../layouts/ProtectedPagesLayout';
import { protectedRoutes } from "../../routers/routes";
import { buildRoutesItems } from "../../utils";
import { _404NotFound } from '../../components/NotFound';

export default function Index() {
    return (
      <ProtectedPagesLayout>
          <Switch>
            {buildRoutesItems(protectedRoutes)}
            <Route component={_404NotFound}/>
          </Switch>
      </ProtectedPagesLayout>
    )
}
