import React from "react";
import { Result } from "antd";
import { Link } from "react-router-dom";

export const _404NotFound = ({url=''}) => {
    return (
        <section className="full-height">

            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you are trying to visit does not exist."
                extra={<Link className="btn btn-primary" type="primary" to={url ? url : "/"}>Back Home</Link>}
            />
        </section>
    )
}