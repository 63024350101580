import Search from 'antd/lib/input/Search'
import { omitBy } from 'lodash';
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import GlobalStateContext from '../../../../context';

export default function CustomSearch({condition, onListUpdate, webservice, onChange, onSearch, placeholder,onLoading }) {
    const itemsPerPage = 15;
    var itemsSet = 1;
    const [loading, setLoading] = useState(true)
    const [searchTerm, setTerm] = useState(null)
    const [items, setItems] = useState([])
    const [payload, setPayload] = useState({ page: itemsSet, perPage: itemsPerPage ,...condition});

    const fetchData = async () => {
        setLoading(true);

        webservice(omitBy(payload, elm => !elm))
            .then(res => res.data.entries)
            .then(entries => entries.sort((a,b)=>  {
                if(a.status < b.status )
                    return -1
                if(a.status > b.status )
                    return  1

                return 0
            
            } ))
            .then(items => setItems(items))
            .then(_ => setLoading(false));

        ;
    }


    useEffect(() => {
        onListUpdate && onListUpdate(items);
    }, [items]);

    useEffect(() => {
        fetchData()
    }, [payload])

    useEffect(() => {
        onLoading && onLoading(loading);
    }, [loading])

    const _onSearch = (title) => {
        if (searchTerm !== null) {
            setPayload({...payload, title})
        }
    }
    return (
        <div >
            <Search
                size="small"
                onChange={onChange || (elm => setTerm(elm.target.value))}
                placeholder={placeholder}
                onSearch={onSearch || _onSearch}
            />
        </div>
    )
}

CustomSearch.defaultProps = {
    placeholder: "Search...",
    initialValue: "",
    onListUpdate: null,
    onLoading: null,
}