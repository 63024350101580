import { Button, Icon, Rate } from 'antd'
import React, { useState } from 'react';
import thanksImage from '../assets/images/undraw_a_whole_year_vnfm.svg'
import IconFont from './IconFont';
import { FacebookProvider, Like } from 'react-facebook';
import env from '../environments';
// import { Follow } from 'react-twitter-widgets'
import Webservice from '../classes/WebService';
import TextArea from 'antd/lib/input/TextArea';
import FormItem from 'antd/lib/form/FormItem';

export default function Rater({ callback, item }) {
    const [visibility, setVisibility] = useState(true)
    const [askForNote, setAskForNote] = useState(false)
    const [note, setNote] = useState("")
    const [value, setValue] = useState()
    const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
    const ratemsg = {
        1: "That hurts but sorry. We will work on improving your experience next time.",
        2: "Sorry to hear that. We will work on improving your experience next time.",
        3: "Not Bad!. We will work on improving your experience next time.",
        4: "Nice one!. We will work on getting a 5-star next time.",
        5: "Awesome! We really appreciate that.",
    }

    const handleSubmit = () => {
            setVisibility(false);
            setTimeout(callback);

            const wb = new Webservice();
            wb.post({
                endpoint: "misc/rate-experience", data: {
                    value,
                    stream: item.id,
                    note
                }
            })
        
    }

    const styles = {
        rateConstainer: {
            margin: "15px 10px"
        },
        rate: {
            // margin: 'px 5px',
            fontSize: '50px'
        },
        appreciations: {
            container: {
                textAlign: 'center'
            }
        }
    }
    return (
        <div className="text-center"  >
            <div hidden={!visibility} >
                <h6  className='text-2xl text-bold mb-3'>
                 How will you rate your experience?
                </h6>
                <div className="text-muted">
                    <p>
                        We are doing the best to give users the right experience.
                        Feedback enables us to that better.
                    </p>
                </div>
                <div style={styles.rateConstainer}>
                    <Rate character={<Icon type="star" style={styles.rate} />} tooltips={desc} onChange={setValue} />
                    {
                        value ?

                        <>
                           {value <= 4 && 
                           <FormItem className="m-t-20">
                            <label>How can we improve this service?</label>
                               <TextArea className="m-t-15" onChange={ evt =>setNote(evt.target.value)} name="note" />
                           </FormItem>
                               }
                            <div>

                            <Button className="m-t-15" onClick={handleSubmit}>Submit</Button>
                            </div>
                        </>
                        :
                        null
                    }
                </div>

            </div>
            <div hidden={true} style={styles.appreciations.container} >
                <h4> We appreciate that. </h4>
                <p>{ratemsg[value]}</p>
                <div>
                    <img width="100%" src={thanksImage} alt="" />
                </div>
                <div className="list">
                    <p><strong>Get in touch with us on the following channels. </strong></p>

                    <ul className="socials">
                        <li>
                            <IconFont style={{ fontSize: '2rem' }} type="icon-twitter" />
                            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/viblica">
                                <div>VIBLICA</div>
                            </a>

                        </li>
                        <li>
                            <IconFont style={{ fontSize: '2rem' }} type="icon-facebook" />
                            <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/viblica">
                                <div>VIBLICA</div>
                            </a>

                        </li>

                    </ul>
                </div>

                <div >

                    <FacebookProvider appId={env.facebook.appID}>
                        <a target="_blank" href="https://facebook.com/viblica">

                            <Like href="http://www.facebook.com/viblica" colorScheme="dark" showFaces={false} />

                        </a>

                    </FacebookProvider>
                    {/* <Follow username="viblica" /> */}
                </div>
            </div>
        </div>
    )
}
