import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Collapse, Icon, Input, List, Tabs, Typography } from 'antd';
import GlobalStateContext from '../../../../context';
import FileUpload from '../../../../components/FileUpload';
import MediapanelWebService from '../../../../classes/MediapanelWebService';
import { IoMdAdd } from "react-icons/io";

export default function MediaPannelBranding(props) {

    const [gState, dispatch] = useContext(GlobalStateContext);
    const { mediaPanel } = gState
    const [state, setState] = useState(props?.branding)
    const [editIndex, setEditIndex] = useState(null)
    const wbs = new MediapanelWebService()

    useEffect(() => {
        dispatchBrandingUpdate(state);
        console.log("[x] ",state);
    }, [state])


    const _onChange = (val, index, key) => {
        console.log(val, index, key)
        state[index][key] = val.target ? val.target.value : ""
        setState([...state])

    }

    const onDoneUpload = (data, type = 'backdrops') => {
        console.log("[x] ",data)
        
        const newdata = [{ url: data?.location, name: data?.originalname },...state[type], ]
        setState({...state,[type]:newdata});
        console.log("[x] ",newdata);

    }

    const onUse = (item) => {
        state['active_backdrop'] = item.url === state.active_backdrop ? null : item.url
        setState({ ...state })
    }


    return (
        <div>
            <Collapse defaultActiveKey={"backdrops"}  accordion={true}>
            <Collapse.Panel className='w-full bh-blue-100' header="Backdrops" key={"backdrops"}>
            <FileUpload
                    allowedTypes={['image/jpeg', 'image/png']}
                    maxFileSize={2}
                    showUploadList={false}
                    onComplete={res=> onDoneUpload(res.fileList[0]?.response?.data,"backdrops")}
                    className="w-full"
                    preview={false}
                    text={"Upload Backdrop"}
                    data={{ bucket: "viblica-assets" }}

                >

                    {/* <Button className='w-full ' > <IoMdAdd size={30}/> </Button> */}
                    <Button icon='upload' className='  rounded-lg w-full h-50 p-3 border m-3 border-blue-500'> Upload Backdrop </Button>
                </FileUpload>
            <div

                className="mediaPannelBranding flex gap-2"

            // dataSource={state?.backdrops}

            >
          
                {
                    state?.backdrops.map(item => {


                        const isActive = state.active_backdrop == item.url;

                        return <div
                        key={item.url}
                            className={` ${isActive ? 'border-solid border-4 border-blue-500' : ''} rounded-lg w-28 h-16 hover:opacity-70 transition duration-200 ease-in-out cursor-pointer`}
                            style={{ backgroundImage: `url(${item.url})`, backgroundSize: 'cover' }}
                            onClick={_ => onUse(item)}
                        >

                            {/* <Button  className='action' onClick={_ => onUse(item)} icon={isActive ? "eye-invisible" : "eye"}></Button> */}
                        </div>



                    })
                }
            </div>

            </Collapse.Panel>
            
            </Collapse>
      

        

        </div>
    )

    function dispatchBrandingUpdate(newState) {
        const final =  { ...mediaPanel, branding: newState }

    
        dispatch({
            type: "SET_ITEM",
            payload: ['mediaPanel',final]
        });
    }
}