import React from 'react'
import { Avatar, Card, Skeleton } from 'antd'
import Meta from 'antd/lib/card/Meta';




export default function LoadingCards({ number = 5, alignment = 'column', avatar = false }) {
    const cards = [];
    for (let index = 0; index < number; index++) {
        cards[index] = <Card key={index} size="small" className="  package-card card stream rounded" loading >
            {
                avatar && <Meta
                avatar={
                  <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                }
                title="Card title"
                description="This is the description"
              />
            }

        </Card>
    }

    return (
        <div className={`grid grid-cols-2 sm:grid-cols-3 md md:grid-cols-4 lg:grid-cols-6  mt-6 gap-x-5 gap-y-5`}>
            {
                cards
            }

        </div>
    )
}

