
import axios from "axios";
import env from "../environments";
import { prepareURL, processValidationErrors, _notification } from "../utils";

 class Webservice {


  constructor() {

    //Singleton declaration

    // if(Webservice._instance){
    //   // throw new Error("Singleton classes can't be instantiated more than once.")
    //   return Webservice._instance;
    // }
    // Webservice._instance = this

    this.headers = {
      "Content-Type": "application/json",
    }
    this.baseUrl = env.app.api.baseUrl;
    this.token = JSON.parse(localStorage.getItem('token'));
    if (this.token) {
      this.headers["Authorization"] = `${this.token.tokenType} ${this.token.accessToken}`;
    }

   Object.freeze(this) //freeze for singleton

  }


  /**
   * 
   * @param {Error} error 
   */
  _handleError(error) {
    if (error.response !== undefined) {
      const { response } = error
      if ([400, 409].indexOf(response.status) > -1) {
        processValidationErrors(response.data.errors);
      }
      if (response.status === 401) {
        const isTokenExpiry = !!response.data.stack ? response.data.stack.indexOf("TokenExpiredError") !== -1 : false;
        const isNoToken = !!response.data.stack ? response.data.stack.indexOf(" No auth token") !== -1 : false;
        if (isTokenExpiry || isNoToken) {
          // _notification.error(`Session Expired. Please login again`);
          localStorage.removeItem("token");
          setTimeout(() => {
             window.location.replace("/login")
          }, 1000);
        }

        return
      }
      // message.warning(`${response.data.message}`);
    }
  }



  async  post({ endpoint = "", data = {} }) {
    const { headers, baseUrl } = this;
    const url = prepareURL(`${baseUrl}${endpoint}`);
    try {
      return await axios.post(url, data, { headers })
        .then(res => res.data)
    } catch (error) {
      //check tokenexpiry
      // if(error.status == 401) this.post
      this._handleError(error);
      throw error
    }
  }


  async  get({ endpoint = "", data = {} }, absolute = false) {
    const { headers, baseUrl } = this;
    const url = absolute ? endpoint : prepareURL(`${baseUrl}${endpoint}`);
    try {
      return await axios.get(url, { params: data, headers })
        .then(res => res.data)

    } catch (error) {
      this._handleError(error);

      throw error
    }
  }

  async  delete({ endpoint = "", data = {} }) {
    const { headers, baseUrl } = this;
    const url = prepareURL(`${baseUrl}${endpoint}`);
    try {
      return await axios.delete(url, { headers })
        .then(res => res.data)

    } catch (error) {
      this._handleError(error);
      throw error
    }
  }
  async  put({ endpoint = "", data = {} }) {
    const { headers, baseUrl } = this;
    const url = prepareURL(`${baseUrl}${endpoint}`);
    try {
      return await axios.put(url, data, { headers })
        .then(res => res.data)

    } catch (error) {
      this._handleError(error);
      throw error
    }
  }
}

// const singleteonInstnace = new Webservice()
// Object.freeze(singleteonInstnace);

export default Webservice;