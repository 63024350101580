import React, { useContext, useEffect } from 'react'
import { Button, Layout, } from 'antd';
import GlobalStateContext from '../context';
import { SET_ITEM } from '../store/actions/types';
import { withRouter, useHistory } from 'react-router-dom';
import Topbar from '../components/protected/Topbar';
import Header from '../components/protected/Header';
import Sidebar from '../components/protected/Sidebar';
import { Animated } from "react-animated-css";
import StreamWebService from '../classes/StreamWebService';
import ShortFooter from '../components/front/ShortFooter';
import { isMobile } from 'react-device-detect';
import { BottomNavigation, BottomNavigationAction, makeStyles } from '@material-ui/core';
import { faLocationArrow, faPlusCircle, faRestroom } from '@fortawesome/free-solid-svg-icons';
import { LocationOn, Restore } from '@material-ui/icons';
import { buildMobileMenu } from '../utils';
import MobileMenu from '../components/MobileMenu';
import WebMediaStream from '../pages/protected/streams/components/WebMediaStream';




const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});
function ProtectedPagesLayout({ children }) {
  const [state, dispatch] = useContext(GlobalStateContext);
  const { collapsed_nav, hide_nav } = state;
  const layoutStyle = {
    marginLeft: (hide_nav || isMobile) ? "0px" : collapsed_nav ? '80px' : '200px'
  }
  const classes = useStyles();

  const fetchUserStreams = async () => {
    const webservice = new StreamWebService()
    await webservice.getStreams().then(res => res.data)

      .then(values => dispatch({
        type: SET_ITEM,
        payload: ['streams', values.entries]
      }));
  }
  useEffect(() => {
    fetchUserStreams();
  }, [])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {!isMobile &&
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={!hide_nav}>
          <Sidebar />
        </Animated>

      }

      <Layout  style={layoutStyle} >
        <div className="" >
          {/* <Header /> */}
          
          <Topbar /> 
          <div className={`bg-white full-height  `} >
            <div className="protected-page-content">
              {children}
            </div>

          {/* {!isMobile && <ShortFooter />} */}

          
          </div>

          {/* <div className="animated-bg pull-right" ><i></i><i></i><i></i></div> */}
        </div>
      </Layout>
      {
        (hide_nav && !isMobile) &&
        <Button
          shape="circle" size="large" color="dark" style={{ position: "fixed", top: "86vh", margin: '5px' }} icon="menu-unfold"
          onClick={_ => dispatch({
            type: SET_ITEM,
            payload: ['hide_nav', !hide_nav]
          })}
        />
      }
      {
            isMobile && <MobileMenu/>
          }
    </Layout>

  )
}


export default withRouter(ProtectedPagesLayout);