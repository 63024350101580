import React, { useEffect, useRef } from 'react';

const AudioVisualizerSmall = ({ mediaStream }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvasElement = canvasRef.current;
    const canvasContext = canvasElement.getContext('2d');

    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();

    const source = audioContext.createMediaStreamSource(mediaStream);
    source.connect(analyser);

    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    const draw = () => {
      requestAnimationFrame(draw);
    
      analyser.getByteFrequencyData(dataArray);
    
      canvasContext.clearRect(0, 0, canvasElement.width, canvasElement.height);
    
      const barWidth = canvasElement.width;
      const barHeight = dataArray[0] / 4; // Scale down the value
    
      // Calculate the fill percentage
      const fillPercentage = (barHeight / canvasElement.height) - 0.5;
      let fillColor = '#bbbbff';
    
      // if (fillPercentage < 0.8) {
      //   fillColor = 'green';
      // }
    
      // Draw the filled bar
      canvasContext.fillStyle = fillColor;
      canvasContext.fillRect(0, 0, barWidth * fillPercentage, canvasElement.height);
    };

    draw();

    return () => {
      analyser.disconnect();
      audioContext.close();
    };
  }, [mediaStream]);

  return <canvas ref={canvasRef} width={150} height={5} />;
};

export default AudioVisualizerSmall;
