
import Webservice from './WebService';



export default class ClientWebService extends Webservice {

    async getUsers() {

        try {
            return await this.get({ endpoint: 'users', data: {} })
        } catch (error) {
            throw error;
        }
    }

    /**
     * Saves Users info
     * @param {*} param0 
     */
    async save({data, userId}) {

        try {
            return userId ? 
            await this.put({ endpoint: `users/${userId}`, data }) : 
            await this.post({ endpoint: `users`, data })
        } catch (error) {
            throw error;
        }
    }
 

    async publicProfileInfo(userId) {
        try {
            return  await this.get({ endpoint: `misc/profile/${userId}`}); 
        } catch (error) {
            throw error;
        }
    }
    async getSubscriptions(userId) {
        try {
            return  await this.get({ endpoint: `users/${userId}/subscriptions`}); 
        } catch (error) {
            throw error;
        }
    }

    async deleteUser(userId) {
        try {
            return  await this.delete({ endpoint: `users/${userId}`}); 
        } catch (error) {
            throw error;
        }
    }


    async follow(userId,data={}) {
     
        try {
            return  await this.post({ endpoint: `misc/follow/${userId}`,data}); 
        } catch (error) {
            throw error;
        }
    }


    async followers(userId) {
        try {
            return  await this.get({ endpoint: `misc/followers/${userId}`}); 
        } catch (error) {
            throw error;
        }
    }

}