import React, { Component } from 'react'
import Joyride from 'react-joyride';

export default class Rider extends Component {

    styles = {
        options: {
            zIndex: 1000,
            arrowColor: '#fff',
            backgroundColor: '#fff',
            beaconSize: 36,
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            primaryColor: '#f04',
            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            textColor: '#333',
            width: undefined,
           
        }
    }
    locale = { back: 'Back', close: 'Ok', last: 'Done', next: 'Next', skip: 'Skip' }

    joyprobs = {
        scrollToFirstStep: true,
        continuous: true,
        disableScrolling: false,
        showProgress: true
    }
    constructor() {
        super();
        this.state = {
            completed: false
        }

    }
    componentWillMount() {
        //Check if user is new
        const completed = localStorage.getItem("introCompleted");
        this.setState({ ...this.state, completed });
    }
    _callback(joyState) {
       
        if (joyState.lifecycle === "complete" && (joyState.step.target === "#addDestinationButton")) {//Final so mark user as complete
            localStorage.setItem("introCompleted", "true");
           
        }
    }
    render() {
        return (
            <div>
                {this.state.completed !== 'true' ?
                    <Joyride
                        {...this.props} styles={this.styles}
                        {...this.joyprobs}
                        callback={this._callback}
                        locale={this.locale} /> :
                    null
                }

            </div>
        )
    }
}
