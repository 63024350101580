import React, { useState } from 'react'
import Subscribe from './Subscribe'
import { Menu, Icon } from 'antd';
import { buildMenuItems } from '../../utils';
import { protectedRoutes, protectedRoutesPrefix } from '../../routers/routes';
import { Link, useLocation } from 'react-router-dom';
import logo from "../../assets/images/logo_transparent_cut_landscape.png";

const { SubMenu } = Menu;

export default function Topbar() {

    const location = useLocation();

    const [activeMenu, setActiveMenu] = useState(location.pathname)


    return (
        <>
            <Subscribe />
            {/* <div className=''>
                <Link className="logo" to={"/" + protectedRoutesPrefix}>
                    <img alt="" className="img-fluid  d-inline-block align-top" src={logo} />
                </Link>

                <Menu theme='dark' onClick={_ => setActiveMenu(_.key)} selectedKeys={[activeMenu]} mode="horizontal"
                    style={{ marginBottom: "10px" }}
                >

                    {buildMenuItems(protectedRoutes)}

                </Menu>
            </div> */}
        </>
    )
}
