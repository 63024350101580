import { Avatar, Card, message } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useEffect, useState } from 'react';
import DasbboardWebservice from '../classes/DashboardWebservice';
import LoadingCards from './LoadingCards';
import calendarImage  from '../assets/images/calendar.png'
import revenueImage  from '../assets/images/money.png'
import viewsImage  from '../assets/images/views.png'



// type StatsProp = {
//     label: String,
//     value: Number,
//     avatar: String | Object
// }

export function StatCard(props) {
    return <>
        <Card  className="stats-card">
            <Meta
                avatar={<Avatar  shape='square' style={{width:"3rem",height:"3rem"}} src={props.avatar || ""} size={'large'} icon={props.icon || "gold"} />}
                title={props.value || "--"}
                description={props.label || "--"} />
        </Card>
    </>;
}

export default function DasboardStats() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    function fetchStats() {
        const wbs = new DasbboardWebservice();
        setLoading(true);
        wbs.fetchDasboardData().then(data => {
            setData(data.data);
        }).finally(e => {
            setLoading(false);
        }).catch(err => {
            message.error("Unable to fetch dashboard data. " + err.message);
        });
    }

    function buildStatCards() {
        const [events, followers, revenue,views] = [data.events, data.followers, data.revenue,data.views]
        return <div className='stats-cards'>
            <StatCard label={"Events"} value={events.total} avatar={calendarImage}/>
            {/* <StatCard label={"Followers"} value={followers.total} avatar={followersImage} /> */}
            <StatCard label={"Revenue"} value={`$${revenue.total}`} avatar={revenueImage} />
            <StatCard label={"Views"} value={views.total} avatar={viewsImage} />
        </div>
        // return data.map(dt => <StatCard/>)
    };

    useEffect(() => {
        fetchStats()
    }, [])


    return (
        <div>
            {
                loading ? <LoadingCards number={5} alignment="row" /> :
                    buildStatCards()
            }
        </div>
    )
}



