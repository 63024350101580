import React, { useContext } from 'react'
import GlobalStateContext from '../../context'
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, Menu, Icon, Avatar, Divider } from 'antd';
import Subscribe from './Subscribe';
import { LOGOUT, SET_ITEM } from "../../store/actions/types";
import { protectedRoutesPrefix } from '../../routers/routes';
import { url, stringInitials } from '../../utils';
import logo from "../../assets/images/logo_transparent_cut_landscape.png";
import DataSummary from './DataSummary';
import liveIcon from '../../live.svg';


export default withRouter(function Header(props) {
    const [state, dispatch] = useContext(GlobalStateContext)
    const { user, hide_nav } = state;

    const queryParams = new URLSearchParams(props.location.search);

    const logout = () => {
        dispatch(
            {
                type: LOGOUT
            }
        )
    }

    const authMenuItems = (
        // <Menu>
        <ul className="list-group" >
            <Link className="list-group-item" to={url("account", true)}> Account </Link>
            <Link to={''} className="list-group-item" onClick={logout}> Logout </Link>
        </ul>


        // </Menu>
    )



        ;
    return (

        !queryParams.get("hideHeader") ?

            <div className="nav-stack" >
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container">

                        <Link className="navbar-brand" to={"/" + protectedRoutesPrefix}>
                            <img hidden={!hide_nav} alt="" className="img-fluid logo d-inline-block align-top" src={logo} onClick={_ => {
                                dispatch({
                                    type: SET_ITEM,
                                    payload: ["hide_nav", !hide_nav]
                                })
                            }} />
                        </Link>
                        {state.isLive ? <Avatar shape="square" src={liveIcon} /> : null}


                        <div className=" " id="navbarNav" style={{ flexDirection: "row-reverse" }}>
                            <ul className="navbar-nav  form-inline">
                                <li className="nav-item m-r-15" key="_1">
                                    <Subscribe />
                                </li>

                                <li className="nav-item m-r-15" key="_2">
                                    <Dropdown overlay={authMenuItems} className="text-info">
                                        <Link to="#">
                                            {user.picture ?
                                                <Avatar src={user.picture} />
                                                :
                                                <Avatar>
                                                    {stringInitials(user.name)}
                                                </Avatar>
                                            }
                                        </Link>
                                    </Dropdown>
                                </li>

                            </ul>
                        </div>

                    </div>
                </nav>
                {/* <DataSummary type="bar"/> */}
            </div>
            :
            <></>

    )
})

