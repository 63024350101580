import React, { useState, useRef, useEffect, useContext } from 'react';
import StreamWebService from './classes/StreamWebService';
import GlobalStateContext from './context';
import { OpenVidu } from 'openvidu-browser';
import { logger } from './utils';
import { SET_ITEM } from './store/actions/types';
import MediapanelWebService from './classes/MediapanelWebService';
import { Device } from 'mediasoup-client';
import _ from "lodash"
import { notification } from 'antd';


async function updateStreamSocketId(socketId, streamid) {
  console.log("updating stream socket id", socketId, streamid)
  const wbs = new StreamWebService();
  await wbs.save({ data: { moderator_sock_id: socketId }, id: streamid })
}



const useIsMounted = () => {
  const isMounted = React.useRef(false);
  React.useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);
  return isMounted;
};

const useWebRTC = (props) => {
  const [state, dispatch] = useContext(GlobalStateContext);
  const { io, user, streamMediaConfig } = state;
  const [roomid, setRoomId] = useState(props.roomid);
  const [requestAccepted, setCallAccepted] = useState(false);
  const [eventEnded, setCallEnded] = useState(false);
  const [localStreams, rtcSetLocalStreams] = useState();
  const [name, setName] = useState(props.name || 'Guest');
  let [partNames, setNames] = useState({});
  const [request, setRequest] = useState({});
  const [requests, setRequests] = useState([]);
  const [userJoined, setUserJoined] = useState(false);
  const [returnedSignal, setReturnedSignal] = useState(false);
  const [myId, setMyID] = useState(io.id);
  const [waitingApproval, setWaitingApproval] = useState(false);

  const [remoteStreams, setRemoteStreams] = useState({});
  const [peers, setPeers] = useState({});
  const [userJustLeft, setUserJustLeft] = useState(false);
  const [streamPeerMap, setStreamPeerMap] = useState({});
  const [streamState, setStreamState] = useState({});
  const [onUserStreamDestroyed, setUserStreamDestroyed] = useState();
  const shadowGuy = "__rcdr__"

  const isMounted = useIsMounted();

  const [isShadow, setIsShadow] = useState(name == shadowGuy);
  const [OV, setOV] = useState(new OpenVidu());
  const [screenOV, setSreenOV] = useState(new OpenVidu());
  const [onPubStreamReady, setOnPubStreamReady] = useState();
  const [onNewStream, setOnNewStream] = useState();
  const [stopScreenSession, setStopScreenSession] = useState();
  const [newOpenViduConfig, updateOpenViduConfig] = useState();
  const [screenTokenReceived, setScreenTokenReceived] = useState();

  const [openviduConfig, setOpenviduConfig] = useState({
    OV: null,
    mySessionId: 'SessionA',
    session: undefined,
    mainStreamManager: undefined,  // Main video of the page. Will be the 'publisher' or one of the 'subscribers'
    publisher: undefined,
    subscribers: [],
    screenSession: undefined,
    screenSessionToken: null

  });


  const onUserLeft = (userSocketId) => {
    /**
     * Loop through is stream peer map to delete the exiting peer 
     */
    console.log("[x] onUserLeft", remoteStreams)
    for (const strm in remoteStreams) {
      const stream = remoteStreams[strm]
      if (!!stream) {
        const data = JSON.parse(stream?.connection?.data)
        console.log('ttt', data?.producer?.producerId, userSocketId, strm)
        if (data?.producer?.producerId == userSocketId) {
          console.log("tttx", stream)
          delete remoteStreams[strm]
        }

        openviduConfig.subscribers.findIndex(val => val.streamId == stream.streamId)

      }
    }

    setRemoteStreams({ ...remoteStreams })
  }


  const onStopStream = (streamId) => {
    /**
     * Loop through is stream peer map to delete the exiting peer 
     */
    console.log("[x] stream stopped", streamId, remoteStreams)

    const stream = remoteStreams[streamId]
    if (!!stream) {
      const data = JSON.parse(stream?.connection?.data)
      delete remoteStreams[streamId]
      setRemoteStreams({ ...remoteStreams })
    }


  }


  const onTokenReceived = ({ token }) => {
    console.log(`Token received ${token}`)

    const session = OV.initSession()

    session.on('streamCreated', event => {
      console.log("[x] streamCreated", event)
      setOnNewStream(event)
    })
    session.on('streamDestroyed', event => {

      setUserStreamDestroyed(event.stream)

    })

    session.on("streamPropertyChanged", event => {
      /** 
       * Re-using onPubStreamReady state cuz the only thing we need to do here is update that stream, 
       * the onPubStreamReady already does. Idealy i should have created an onChangeState but that means more code
    
      */
      console.log("streamPropertyChanged", event)
      setOnPubStreamReady(event)
    })


    session.on('exception', (exception) => {
      console.warn(exception);
    });



    session.connect(token, { producer: { name, producerId: myId } })
      .then(async (ress) => {

        console.log("[x] session connected", ress, streamMediaConfig)

        // Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
        // element: we will manage it on our own) and with the desired properties
        let publisher = await OV.initPublisherAsync(undefined, {
          audioSource: streamMediaConfig?.audioinput, // The source of audio. If undefined default microphone
          videoSource: streamMediaConfig?.videoinput, // The source of video. If undefined default webcam
          publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
          publishVideo: true, // Whether you want to start publishing with your video enabled or not
          resolution: '640x480', // The resolution of your video
          frameRate: 30, // The frame rate of your video
          insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
          mirror: true, // Whether to mirror your local video or not

        });


        publisher.on('streamCreated', evt => {
          console.log("Producer ceted", evt.stream, JSON.stringify(evt.stream.streamId))
          setOnPubStreamReady(evt)

        })

        session.publish(publisher);

        session.on("signal:disable_audio", (evt) => {
          console.log("about to ", evt)
          publisher.publishAudio(false)
        });
        session.on("disable_video", (evt) => publisher.publishVideo(false));

        // Obtain the current video device in use
        var devices = await OV.getDevices();
        var videoDevices = devices.filter(device => device.kind === 'videoinput');
        var currentVideoDeviceId = publisher.stream.getMediaStream().getVideoTracks()[0].getSettings().deviceId;
        var currentVideoDevice = videoDevices.find(device => device.deviceId === currentVideoDeviceId);


        updateOpenViduConfig(     // Set the main video in the page to display our webcam and store our Publisher
          {
            currentVideoDevice: currentVideoDevice,
            mainStreamManager: publisher,
            publisher: publisher,
            session
          });
      })
      .catch((error) => {
        console.log('There was an error connecting to the session:', error.code, error.message);
        notification.error("Sorry, we could not connect you. Refresh this page from browser to try again")
      });

    setOpenviduConfig({
      ...openviduConfig,
      token,
      session
    });

  }
  const onScreenTokenReceived = async ({ token }) => {


      console.log("openvidu_token_screen",token,openviduConfig.screenSession)
      await openviduConfig.screenSession.connect(token, { producer: { name, producerId: myId } })
      publishScreen(openviduConfig.screenSession)



  }

  const shareScreen = async (shouldShare = true, callback = () => { }) => {
    
    if(!shouldShare){

      const screenSession = openviduConfig.screenSession;
      screenSession.disconnect();
      callback({success:true});
      setOpenviduConfig({ ...openviduConfig, screenSession:undefined })
      return;

    }
   
      console.log("No Session ")

     

      const screenSession = openviduConfig.screenSession ?? screenOV.initSession();


      console.log("Screen Session ", screenSession)
      screenSession.on('streamCreated', event => {
        console.log("[x] Screen Stream Created", event)
        // setOnNewStream(event)
      })

      setOpenviduConfig({ ...openviduConfig, screenSession })
      io.emit("get_screen_token", { roomid, name: `${name}_screen` });
    
  }


  async function  onStopScreenSession(session) {
    console.log("stopScreenSession", session)
    if(session){

      if (session.connection && session?.connection?.connectionId) {
        await session.disconnect()
  
        setOpenviduConfig({...openviduConfig, screenSession:session})
      
      }
    }

  }

  async function publishScreen(screenSession) {
    //stop previous publishers
    const callback = props.screenShareCallback;
    console.log("publishScreen",callback)
    for (let publisher of screenSession?.openvidu?.publishers) {

      console.log(publisher)

      await screenSession.unpublish(publisher)

    }



    let publisher = await screenOV.initPublisherAsync(undefined, {

      videoSource: 'screen', // The source of video. If undefined default webcam

    });


    publisher.on('streamCreated', evt => {
      
      console.log("Producer created", evt.stream, JSON.stringify(evt.stream.streamId))

      // setOnPubStreamReady(evt)

    })

    publisher.once("accessDenied", evt => {
      console.log("accessDenied", evt)

      callback({ status: false, message: "Screen share access denied" })
      setStopScreenSession(screenSession)

    })
    publisher.on("streamDestroyed", evt => {
      console.log("streamDestroyed", evt)
      callback({ status: false, message: "Screen share stream destroyed" })
      // console.log("[x] stopped")
      setUserStreamDestroyed(evt.stream)
      setStopScreenSession(screenSession)
    })

    publisher.stream.getMediaStream().getVideoTracks()[0].addEventListener('ended', () => {
      console.log('User pressed the "Stop sharing" button');
      callback({ status: false, message: "Screen share stopped" })
      setUserStreamDestroyed(publisher.stream)
      setStopScreenSession(screenSession)
      // console.log("[x] stopped")
    });



    screenSession.publish(publisher).then(_ => {
      callback({ status: true, message:null })

    }).catch(err => {
      callback({ status: false, message: "Screen share failed" })
      setStopScreenSession(screenSession)
    });
  }
  const leaveSession = () => {

    // --- 7) Leave the session by calling 'disconnect' method over the Session object ---

    const mySession = openviduConfig.session;

    if (mySession) {
      mySession.disconnect();
    }

    // Empty all properties...
    setOV(null);
    setOpenviduConfig({
      session: undefined,
      subscribers: [],
      mySessionId: 'SessionA',
      myUserName: 'Participant' + Math.floor(Math.random() * 100),
      mainStreamManager: undefined,
      publisher: undefined
    });
  }

  const deleteSubscriber = (stream) => {
    let subscribers = openviduConfig.subscribers;
    let index = subscribers.findIndex((item) => item.stream.streamId == stream.streamId);
    if (index > -1) {
      console.log("REMX", `${stream.streamId}`, remoteStreams, stream, subscribers)
      subscribers.splice(index, 1);
      setOpenviduConfig({
        ...openviduConfig,
        subscribers,
      });


    }
  }


  useEffect(() => {

    if (onPubStreamReady) {
      console.log("onPubStreamReady", onPubStreamReady)

      const streams = { ...remoteStreams }

      streams[onPubStreamReady.stream.streamId] = onPubStreamReady.stream
      setRemoteStreams(streams)

    }

  }, [onPubStreamReady])

  useEffect(() => {

    if (onNewStream) {

      const sub = openviduConfig.session.subscribe(onNewStream.stream, undefined)
      const subscribers = [...openviduConfig.subscribers, sub]

      setOpenviduConfig({ ...openviduConfig, subscribers })

    }

  }, [onNewStream])

  useEffect(() => {

    if (newOpenViduConfig) {

      console.log(newOpenViduConfig)

      setOpenviduConfig({ ...openviduConfig, ...newOpenViduConfig })

    }

  }, [newOpenViduConfig])
  useEffect(() => {

    const streams = { ...remoteStreams }

    console.log("[1]", openviduConfig.subscribers)
    for (let sub of openviduConfig.subscribers) {

      streams[sub.stream.streamId] = sub.stream


    }

    console.log("[🙏🏽]", streams)
    setRemoteStreams(streams)

  }, [openviduConfig.subscribers])

  useEffect(() => {
    if (!!name) {

      setIsShadow(name == shadowGuy)

    }

  }, [name])


  useEffect(() => {
    console.log("REMX", `${onUserStreamDestroyed?.streamId}`)
    if (!!onUserStreamDestroyed?.streamId) {

      deleteSubscriber(onUserStreamDestroyed)
      onStopStream(onUserStreamDestroyed.streamId)

    }

  }, [onUserStreamDestroyed])



useEffect(() => {
  
  stopScreenSession && onStopScreenSession(openviduConfig.screenSession)
  console.log("We heres",openviduConfig)

}, [stopScreenSession])


  // function getMediaPannel(persistedMediapannel) {
  //   const wbs = new MediapanelWebService();
  //   wbs.getDefault().then(res => {
  //     // console.log("Diff",  _.merge(res.data,persistedMediapannel));

  //     dispatch({
  //       type: SET_ITEM,
  //       payload: ['mediaPanel', _.merge(res.data, persistedMediapannel)]
  //     })
  //   })

  // }

  const onJoin = (data) => {

    const { clients, names } = data

    console.log("[x] OnJoin", names, clients, data.streamState)
    const participants = {}
    setNames(names);
    !props.isModerator && setStreamState(data.streamState);
    console.log("[x] onjoin stream state", data.streamState)
    // props.isModerator && getMediaPannel(streamState?.mediaPanel)



    console.log("[x] Setting streamPeerMap on join ",)

  }

  const approveWhitelist = (whitelist = []) => {

    whitelist.forEach(element => {
      io.emit('approveWhitelist', { to: element.from, roomid, from: myId });
    });
  }

  const cleanUp = () => {



    if (!isMounted.current) {
      console.log("[x] Unmounted")
      io.emit("leaving", roomid)
      console.log("Cleaning up...")
      unregisterSocketEvents();

    }


  }
  const unregisterSocketEvents = () => {
    io.off("incomingRequest")
    io.off("userLeft");

    io.off("receiving returned signal");

    io.off("user joined");

    io.off("joined");
    io.off("requestAccepted");
  }
  useEffect(() => {

    io.on('incomingRequest', ({ from, name }) => {
      console.log("Incoming request", { from, name });
      // setRequest({ isReceivingCall: true, from, name });

      name == shadowGuy ?
        acceptRequest({ from, name })
        :
        setRequests([...requests, { from, name }])

    });

    io.on('userLeft', function ({ id }) {
      console.log('userLeft', id)
      setUserJustLeft(id);
    });

    io.on("receiving returned signal", payload => {
      console.log("receiving returned signal")
      setReturnedSignal(payload)
    });

    io.on("user joined", payload => {
      setUserJoined(payload)
    });

    io.on("openvidu_token", onTokenReceived)

    io.on("openvidu_token_screen", function(data){
      setScreenTokenReceived(data)
    })

    return cleanUp

  }, []);


  /**
   * Listen to track changes on peer connection and update remote stream variable
   * This should happen only when peer connection is set
   * 
   */


  useEffect(() => {

    if(!!screenTokenReceived){
    
      onScreenTokenReceived(screenTokenReceived)

    }

  }, [screenTokenReceived])


  useEffect(() => {



    props.isModerator ? enterStudio() : requestToJoin(roomid)


  }, [])

  useEffect(() => {
    //Automtically accept users request to join room
    if (request.isReceivingCall) {
      acceptRequest()
    }

  }, [request])
  useEffect(() => {
    //Automtically accept users request to join room
    if (returnedSignal) {
      console.log(returnedSignal)
      const peer = peers[returnedSignal.id];
      peer.signal(returnedSignal.signal);
    }

  }, [returnedSignal])


  useEffect(() => {
    //Automtically accept users request to join room
    if (!!userJoined) {
      const payload = userJoined;
      console.log("[x] User Joined", userJoined)
      const participants = {}
      // const pc = addPeer(payload.signal, payload.callerID, Object.values(localStreams)[0], payload.name);

      participants[payload.callerID] = payload.callerID

      setPeers({ ...peers, ...participants });
    }

  }, [userJoined])


  useEffect(() => {


    if (!!userJustLeft) {
      console.log("userJustLeft")
      onUserLeft(userJustLeft)
    }

  }, [userJustLeft])
  useEffect(() => {
    console.log("checking if ov session exist")
    if(OV.session){
      console.log("about to disconnect session")
      OV.session.disconnect();
    }

  }, [eventEnded])





  const acceptRequest = async (request) => {

    /**
     * @TODO: initiate peer connection here before sending out acceptance. 
     * Currently this is created at the requested side of the onJoin
     * 
     */
    console.log("[x] On Accept", request)
    const peerId = request.from;
    partNames = { ...partNames, [request.from]: request.name }
    setNames(partNames)
    io.emit('acceptRequest', { to: peerId, roomid, from: myId, name: request.name });
    setRequests(requests.filter(req => req.from != request.from));

  };
  const denyRequest = async (request) => {
    const peerId = request.from;
    io.emit('denyRequest', { to: peerId, roomid, from: myId });
    setRequests(requests.filter(req => req.from != request.from));

  };


  /**
   * Enters the socket event room. this is only for moderators
   * @returns 
   */
  const enterStudio = () => {


    updateStreamSocketId(myId, roomid).then(_ => {

      io.on("joined", ({ clients, names, streamState, waitlist }) => {

        onJoin({ clients, names, streamState, waitlist });
        setRequests(waitlist);
      })

      io.emit('enterStudio', { roomid, id: myId, name })


    })




  }

  const requestToJoin = async (roomid, isModerator = false) => {

    //get moderator to connect to
    const moderatorSocketID = props.event.moderator_sock_id;

    io.on('requestAccepted', ({ from, clients, names, streamState }) => {

      setCallAccepted(true);
      onJoin({ clients, names, streamState })
      setWaitingApproval(false)

    });


    io.emit('requestToJoin', { roomid, from: myId, name });
    setWaitingApproval(true)

  };
  const requestToDisable = (type, connection) => {
    console.log("tttt", type)
    openviduConfig.session.signal({ type: `disable_${type}`, to: [connection] })
  }

  const leave = () => {
    console.log("Leave function called")
    OV.session && OV.session.disconnect();
    
    // window.location.reload();
  };

  return {
    requests,
    requestAccepted,
    localStreams,
    remoteStreams,
    rtcSetLocalStreams,
    name,
    setName,
    eventEnded,
    myId,
    requestToJoin,
    leave,
    acceptRequest,
    setRoomId,
    roomid,
    streamPeerMap,
    streamState,
    setStreamState,
    denyRequest,
    waitingApproval,
    requestToDisable,
    leaveSession,
    shareScreen,
    shadowGuy
  }
};


export { useWebRTC };

