
import Webservice from './WebService';



export default class ConfigurationWebService extends Webservice {

    async getConfigs() {

        try {
            return await this.get({ endpoint: 'configurations', data: {} })
        } catch (error) {
            throw error;
        }
    }

    async getConfigValues() {

        try {
            return await this.get({ endpoint: 'configurations/values', data: {} })
        } catch (error) {
            throw error;
        }
    }

    /**
     * Saves Config info
     * @param {*} param0 
     */
    async save({data, id}) {

        try {
            return id ? 
            await this.put({ endpoint: `configurations/${id}`, data }) : 
            await this.post({ endpoint: `configurations`, data })
        } catch (error) {
            throw error;
        }
    }
    /**
     * Saves Config info
     * @param {*} param0 
     */
    async update({data}) {

        try {
            return   await this.put({ endpoint: `configurations/values`, data }) 
        } catch (error) {
            throw error;
        }
    }
 

    async getConfig(id) {
        try {
            return  await this.get({ endpoint: `configurations/${id}`}); 
        } catch (error) {
            throw error;
        }
    }


}