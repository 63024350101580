import React from 'react'
import bg from '../../../../assets/images/whatsappchatbg.jpeg';
import { Link } from 'react-scroll'
export default function WaitingForStream(props) {
    const { text, style, backdrop, height, width, description } = props;
    const defaultStyle = {
        width: width || '100%', height: height || '300px', borderRadius: 0,
        // border: "1px solid #e3e3e3",
        backgroundImage: `url(${backdrop})`,
        backgroundSize: "cover",
        // background: "#202124",
        ...style
    };


    return (
      
            <div className={` ${props.className || "card-img-top bg-blue-100"}`} style={defaultStyle}>

            <div className={` ${props.className || "card-img-top bg-blue-100"} ${backdrop && "bg-black bg-opacity-95"}`} >
                <div className='flex flex-col justify-center h-full w-full'>

             
                

                   

                        <h5 className="text-center align-center text-xl">

                           { text ?? 'Waiting for signal'}


                        </h5>


                        <div className='flex justify-center text-center  m-t-5'>

                            <div className=" " style={{ color: "#89afd7" }} to="streamFrom" spy={"true"} smooth={"true"} offset={50} duration={500} >
                                {description}
                            </div>
                        </div>
                    
                
                   </div>
            </div>
            </div>
       
    )
}
