import React, { useContext, useState } from 'react'
import GlobalStateContext from '../context'
import {  withRouter } from 'react-router-dom';
import {  message, Button } from 'antd';
import PaymentWebService from '../classes/PaymentWebService';
import { PAYMENT_FLUTTERWAVE, PAYMENT_PAYSTACK } from '../constants';
import LocalMoneyValue from './LocalMoneyValue';
import { FlutterwavePayment, Paystack } from './protected/Subscribe'


export function Pay(props) {
    const { location, amount, entity, type = 'ppv', redirect_url, showButton,showPopup , payment_method} = props;
    const txWebservice = new PaymentWebService();
    const [state] = useContext(GlobalStateContext);

    const { currency, user, xrate,userLocationData } = state;
    const [txId, setTxId] = useState("")
    const [_loading, setLoading] = useState(false);
    const [showPayment, setShowPayment] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState( payment_method || (currency.code === "GHS"  ? PAYMENT_PAYSTACK: PAYMENT_FLUTTERWAVE))
    const [modalVisibility, setModalVisibility] = useState(showPopup||true)

    const payable = amount //@TODO Add coupon call, tax, etc



    const onPaymentSuccess = (resp) => {
        //Submit payment to api
        setLoading(false);
        setShowPayment(false);
        closeModal();
        window.location.reload();
        return true;
    }

    const onPaymentClose = (resp) => {
        txWebservice.deleteTransaction(txId)
            .then(resp => console.log(resp));
        //Submit payment to api
        setLoading(false);
        setShowPayment(false);
        return true;
    }




    const closeModal = () => {
        setModalVisibility(false);
    }


    const onClickPay = () => {
        setLoading(true)
        console.log("offfff", entity)
        const dxPayload = {
            entity_id: entity.id || entity._id,
            payment_method: paymentMethod ,
            currency_code: currency.code,
            type
        }

        txWebservice.pay(dxPayload)
            .then(resp => resp.data)
            .then(txID => {
                setTxId(txID?.reference);
                setShowPayment(true)
                return;
            })
            .catch(err => {
                setLoading(false);
                console.log(err)
                if(err?.response?.status !== 401){

                    message.error("Failed. Please try later")
                }
            })
    }



    return (
        <>
            <Button type="primary"
                hidden={!showButton}
                onClick={onClickPay} >
                    Pay&nbsp;
                     {<LocalMoneyValue
                   
                    value={payable} showSymbol={true}
                />} </Button>
            {
                paymentMethod === PAYMENT_FLUTTERWAVE && 
           
            <FlutterwavePayment
                currency={currency.code}
                visible={showPayment}
                user={user}
                amount={(parseFloat(payable) * parseFloat(xrate)).toFixed(2)}
                txref={txId}
                onClose={onPaymentClose}
                onSuccess={onPaymentSuccess}
                title={`Onetime Payment `}
                description={`Onetime Payment for ${entity.title || entity.name} `}
                redirect_url={redirect_url}
                meta={{entity}}
                country ={userLocationData.country_code}
            />
        }

{
                paymentMethod === PAYMENT_PAYSTACK && 
            <Paystack
                    currency={currency.code}
                    visible={showPayment}
                    user={user}
                    amount={(parseFloat(payable) * parseFloat(xrate) * 100).toFixed(2)}
                    txref={txId}
                    onClose={onPaymentClose}
                    onSuccess={onPaymentSuccess}
                    title={`Onetime Payment `}
                    description={`Onetime Payment for ${entity.title || entity.name} `}
                    redirect_url={redirect_url}
                    meta={{entity}}
                    country ={userLocationData.country_code}
                />

}

        </>
    )
}

export default withRouter(Pay);