import React, { useEffect } from 'react'
import styled from 'styled-components'
import styles from "./classic.scss"

export default function ClassicBanner(props) {
    const Title = styled.strong `
      color: ${props?.style?.title?.color };
      background: ${props?.style?.title?.background };
      padding: 0px 1.5%;
    `
    const Text = styled.span `
      color: ${props?.style?.text?.color };
      background: ${props?.style?.text?.background };
      padding: 0px 1.5%;
    `

  useEffect(() => {

    console.log("test", props)

  }, [])



  return (
    <div className='classicBanner'>
      <div className='news-banner'>

        <div className="container">
        
          <div className="headline">
            <h1 className='text-white'><Title>{props.title ?? "Breaking News:"}</Title> <Text>{props.description ?? "Your text goes here"}</Text></h1>
          </div>
        </div>
      </div>
    </div>
  )
}
