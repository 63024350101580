import React, { useContext, useState } from 'react';
import StreamWebService from '../../classes/StreamWebService';
import { Icon } from 'antd';
import GlobalStateContext from '../../context';

const PostComment = ({ onPost, streamId }) => {
  const [comment, setComment] = useState('');
  const [textInFocus, setTextInFocus] = useState(false);
  const [loading, setLoadind] = useState(false);
  const [state, dispatch] = useContext(GlobalStateContext)
  const { io, user } = state;


  const handleChange = (e) => {
    setComment(e.target.value);
  };


  const postComment = () => {
    const swbs = new StreamWebService()
    swbs.postComment(streamId, { content: comment }).then(resp => {
      console.log("[x] chats", resp);

      onPost(resp.data);
      setComment('');



    }).finally(_ => {
      setLoadind(false)
    })

  }

  const handleSubmit = (e) => {
    console.log(e)
    setLoadind(true)
    e.preventDefault();
    if (!comment.trim()) return;
    postComment()

  };

  return (

    <form onSubmit={handleSubmit} className="mb-4">
      <input
        type='text'
        value={comment}
        onChange={handleChange}
        onFocus={e_ => { setTextInFocus(true) }}

        className="w-full px-3 py-2 border-b border-gray-300 focus:outline-none focus:border-blue-500"
        placeholder="Write your comment..."
      />
    {!user && <span> Login to post a comment </span>}
      <div className=' flex flex-row gap-2'>

        <button
          disabled={loading}
          hidden={!textInFocus}
          onClick={_ => { setComment(''); setTextInFocus(false) }}
          className="mt-2 px-3 py-2  text-black rounded-md hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
        >
          Cancel
        </button>
        <button hidden={!textInFocus}
          disabled={loading || !user}
          type='submit'
          className="mt-2 px-3 py-2 bg-gray-200 text-black rounded-md hover:bg-gray-300 focus:outline-none focus:bg-gray-400"
        >
          {loading ? <Icon type="loading" /> : 'Comment'}
        </button>
      </div>
  
    </form>
  );
};

export default PostComment;
