import React, { lazy } from 'react';
import Terms from '../pages/front/Terms';
import PrivacyPolicy from '../pages/front/PrivacyPolicy';



const DestinationsPage =  lazy(() => import('../pages/protected/DestinationsPage'));
const ChannelComponent =  lazy(() => import('../components/ChannelComponent'));
const LiveEvents =  lazy(() => import('../components/front/LiveEvents'));
const ViewEventPage = lazy(() => import("../pages/front/ViewEventPage"));
const ViewPublicStreamPage = lazy(() => import("../pages/protected/streams/ViewPublicStreamPage"));
const Dashboard = lazy(() => import("../pages/protected/Dashboard"));
const Landing = lazy(() => import("../pages/front/Landing"));
const LoginOTP = lazy(() => import("../pages/front/LoginOTP"));
const ConfigurationPage = lazy(() => import("../pages/protected/settings/ConfigurationPage"));
const StreamIndexPage = lazy(() => import("../pages/protected/streams/StreamIndexPage"));
const Signup = lazy(() => import("../pages/front/Signup"));
const ViewStreamPage = lazy(() => import("../pages/protected/streams/ViewStreamPage"));
const JoinBroadcast = lazy(() => import("../pages/protected/streams/JoinBroadcast"));
// const MeetPage = lazy(() => import("../pages/protected/streams/MeetPage"));
const ExternalSource = lazy(() => import("../pages/protected/streams/ExternalSource"));
const Account = lazy(() => import("../pages/protected/Account"));
const VerificationPage = lazy(() => import("../pages/front/VerificationPage"));
const ResetPassword = lazy(() => import("../pages/front/ResetPassword"));
const PricingPage = lazy(() => import("../pages/front/PricingPage"));
const CreateStreamPage = lazy(() => import("../pages/protected/streams/CreateStreamPage"));
const UploadVideoPage = lazy(() => import("../pages/protected/streams/UploadVideoPage"));
const StreamBasePage = lazy(() => import("../pages/protected/streams/StreamBasePage"));

export const protectedRoutesPrefix = "app";

export const protectedRoutes = [
    {
        id:"dashboard",
        name: "Dasbboard",
        breadcrumbName: "Dashboard",
        component: <Dashboard/>,
        path: `/${protectedRoutesPrefix}`,
        icon: "dashboard",
        exact: true

    },
    {
        id:"mystudio",
        name: "My Studio",
        breadcrumbName: "Studio",
        component: <ViewStreamPage/>,
        path: `/${protectedRoutesPrefix}/primary`,
        icon: "experiment",
        exact: true,
        visible:false

    },


    // {
    //     id:"meet",
    //     name: "Meet",
    //     breadcrumbName: "Meet",
    //     component: <MeetPage/>,
    //     path: `/${protectedRoutesPrefix}/meet`,
    //     icon: "deployment-unit",
    //     exact: true

    // },
    {
        id:"myevents",
        name: "My Events",
        breadcrumbName: "Events",
        component: <StreamIndexPage/>,
        path: `/${protectedRoutesPrefix}/events`,
        icon: "calendar",
        exact: true

    },

    {
        id:"destinations",
        name: "Destinations",
        breadcrumbName: "Destinations",
        component: <DestinationsPage/>,
        path: `/${protectedRoutesPrefix}/destinations`,
        icon: "deployment-unit",
        exact: true

    },
    // {
    //     name: "Dashboard",
    //     breadcrumbName: "Dashboard",
    //     component: <Dashboard/>,
    //     path: `/${protectedRoutesPrefix}`,
    //     icon: "dashboard",
    //     exact: true

    // },
    {
        id:"watch",
        name: "Watch",
        breadcrumbName: "Watch",
        component: <LiveEvents/>,
        path: `/${protectedRoutesPrefix}/others`,
        icon: "play-circle",
        exact: true

    },

    {
        name: "Number Events",
        breadcrumbName: "Events",
        component: <StreamIndexPage />,
        path: `/${protectedRoutesPrefix}/stream`,
        icon: "video-camera",
        visible:false,
        children: [
            {
                name: "View Stream",
                breadcrumbName: "View Stream",
                component: <ViewStreamPage />,
                path: '/:id/view',
                icon: "camera",
                visible:false
            }
            ,
            {
                name: "Watch Stream",
                breadcrumbName: "Watch Stram",
                component: <ViewPublicStreamPage/>,
                path: '/:id/watch',
                icon: "eye",
                visible:false
            },
            {
                name: "Create Event",
                breadcrumbName: "Create Stream",
                component: <CreateStreamPage/>,
                path: '/create',
                icon: "plus"
            },
            {
                name: "Upload Video",
                breadcrumbName: "Upload Video",
                component: <UploadVideoPage/>,
                path: '/upload',
                icon: "plus"
            },
            {
                name: "Create Event",
                breadcrumbName: "Edit Stream",
                component: <CreateStreamPage/>,
                path: '/:id/edit',
                icon: "plus"
            }
        ]

    },


    {
        id: "account",
        name: "Account",
        breadcrumbName: "Account",
        component: <Account />,
        path: `/${protectedRoutesPrefix}/account`,
        icon: "user",

    },

    {
        
        name: " Setup",
        breadcrumbName: " Setup",
        component: "",
        path: `/${protectedRoutesPrefix}/`,
        icon: "setting",
        visible:false,
        children: [
            {
                name: "Config",
                breadcrumbName: "Configuration",
                component: <ConfigurationPage />,
                path: 'config',
                icon: "setting"
            }
        ]

    },
]
export const routes = [
    {
        path: '/',
        component: <Landing />,
        name: "Welcome Page",
        icon: "home",
        exact: true

    },

    {
        path: '/channel/:id',
        component: <ChannelComponent/>,
        name: "channel",
        icon: "home",
        exact: true

    },

    {
        path: '/login',
        component: <LoginOTP action="login" />,
        name: "Client Login",
        icon: "dashboard",
        exact: true

    },
    {
        path: '/signup',
        component: <LoginOTP action="signup" />,
        name: "Signup",
        icon: "dashboard",
        exact: true

    },
    {
        path: '/reset-password',
        component: <ResetPassword />,
        name: "Reset Password",
        icon: "key",
        exact: true

    },
    {
        path: '/verification/email',
        component: <VerificationPage />,
        name: "Verify Email",
        icon: "email",
        exact: true

    },
    {
        path: '/terms',
        component: <Terms />,
        name: "Terms and Conditions",
        icon: "law",
        exact: true

    },
    {
        path: '/privacy',
        component: <PrivacyPolicy/>,
        name: "Terms and Conditions",
        icon: "law",
        exact: true

    },
    {
        path: '/pricing',
        component: <PricingPage/>,
        name: "Pricing",
        icon: "money",
        exact: true

    },
    {
        name: "Watch Event",
        breadcrumbName: "Watch Stram",
        component: <ViewEventPage/>,
        path: '/:id/watch',
        icon: "eye"
    },
    {
        name: "Embed",
        breadcrumbName: "Embed",
        component: <ViewEventPage/>,
        path: '/:id/embed',
        icon: "eye"
    },

    // {
    //     name: "Join Broadcast",
    //     breadcrumbName: "Join Broadcast",
    //     component: <JoinBroadcast />,
    //     path: '/:id',
    //     icon: "camera",
    //     visible:false
    // }
    {
        name: "Event Base",
        breadcrumbName: "Join/Start Broadcast",
        component: <StreamBasePage />,
        path: '/:id',
        icon: "camera",
        visible:false
    }
    
]