import React, { useState,useEffect, useContext } from 'react'
import StreamWebService from '../../classes/StreamWebService'
import CommentCard from './CommentCard'
import PostComment from './PostComment'
import { Empty } from 'antd'
import GlobalStateContext from '../../context'
import { EVT_LIVECHAT_INCOMING } from '../../constants'


export default function Comments({streamId}) {

    const [comments, setComments] = useState([])
    const [state, dispatch] = useContext(GlobalStateContext)
    const { io,user } = state;


    const getComments=()=>{
        const swbs = new StreamWebService()
        swbs.getChat(streamId).then(resp=>{
            console.log("[x] chats",resp);
            setComments(resp.data);
        })

    }

    const onNewLiveMessage = (data)=>{
        console.log(data)
     
        comments.push({
            from: {name:data?.sender_name,picture:data.image},
            content: data?.content,
            createdAt: data?.time,
        })

    }

    useEffect(() => {
        getComments();
        io.on(`${EVT_LIVECHAT_INCOMING}_${streamId}`, onNewLiveMessage)
    
    }, [])
    

  return (
    <div>
        <PostComment streamId={streamId} onPost={_=> getComments()}/>
        
        {
            comments.length > 0 ?
            comments.map(comment=> <CommentCard from={comment?.from?.name}  content={comment.content} timestamp={comment.createdAt} />)
            :

            <Empty description="No comments yet"/>
        }
    </div>
  )
}
