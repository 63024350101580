import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Collapse, Input, List, Tabs, Typography } from 'antd';
import GlobalStateContext from '../../../../context';
import Paragraph from 'antd/lib/typography/Paragraph';
import MediaPannelBranding from './MediaPannelBranding';
import MediapanelWebService from '../../../../classes/MediapanelWebService';
import MediaPannelBanners from './MediaPannelBanners';




export default function MediaPannel(props) {

    const { Panel } = Collapse;

    const [gState, dispatch] = useContext(GlobalStateContext);
    const [pannel, setPanel] = useState({})
    const wbs = new MediapanelWebService()
        // useRef to track whether it's the initial render
        const isInitialRender = useRef(true);

    const callback = (index) => {
        console.log(index);
    }

    const getMediaPanels= ()=>{
 
        wbs.getMediapanels().then(res=>{
            console.log("[x] fff",res.data)
            setPanel(res.data)
            dispatch({
                type: "SET_ITEM",
                payload: ['mediaPanel', {...res.data}]
            })
        })
    }


    useEffect(() => {
        console.log("fff",gState.mediaPanel)
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }

       if(gState.mediaPanel){
        console.log("[x] fff2",gState.mediaPanel)
           // update user mediapannel
           wbs.save(gState.mediaPanel)
       }
        
    }, [gState.mediaPanel])


    useEffect(() => {

        getMediaPanels();
        
    }, [])


    const [state, setState] = useState({})

    return (
        <Tabs bordered={false} defaultActiveKey={'1'} onChange={callback}>
           
            <Tabs.TabPane tab={<strong>Branding</strong>} key="1">
                {/* <p>{text}</p> */}
                {pannel?.branding &&  <MediaPannelBranding branding={pannel?.branding} />}
            </Tabs.TabPane>
            <Tabs.TabPane tab={<strong>Banners</strong>} key="2">
             {pannel?.banner &&   <MediaPannelBanners banner={pannel?.banner} />}
            </Tabs.TabPane>

        </Tabs>
    )
}
