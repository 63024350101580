import moment from 'moment';
import React from 'react';

const CommentCard = ({ from, content, timestamp }) => {
  return (
    <div className=" rounded-lg p-1 mb-1">
      <div className="flex items-center mb-2">
        <div className="flex-shrink-0 mr-2">
          <img
            className="w-10 h-10 rounded-full"
            src="https://via.placeholder.com/150"
            alt="User Avatar"
          />
        </div>
        <div>
          <div className="font-semibold">{from ?? "N/A"} <span className="text-gray-400 text-sm font-light">{ moment(timestamp).fromNow(true)} ago</span></div>

          <div className="text-gray-800">{content}</div>
        </div>
      </div>
    </div>
  );
};

export default CommentCard;