import React from 'react'
import { Avatar, Card, Skeleton } from 'antd'
import Meta from 'antd/lib/card/Meta';



// enum Alignment {
//     row, column
// }
export default function LoadingSmallCards({ number = 5, alignment = 'column', avatar = false }) {
    const cards = [];
    for (let index = 0; index < number; index++) {
        cards[index] = <Card key={index} style={{width:"100px", height:"85px", borderRadius:"10px"}} size="small" className="  package-card card stream" loading >
            

        </Card>
    }

    return (
        <div className={`${alignment}  streams  ml-0 mr-0`}>
            {
                cards
            }

        </div>
    )
}

