import React from 'react'
import { Icon } from 'antd';

export default function IconFont(props) {
    const IconFont = Icon.createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
      });
    return (
        <>
            <IconFont {...props}/>
        </>
    )
}
