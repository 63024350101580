import Webservice from './WebService';



export default class CouponWebService extends Webservice {

    async getCoupon(id) {

        try {
            return await this.get({ endpoint: `coupons/${id}` })
        } catch (error) {
            throw error;
        }
    }
    async getCouponByCode(code) {

        try {
            return await this.get({ endpoint: `coupons/lookup/${code}` })
        } catch (error) {
            throw error;
        }
    }

    async getCouponTypes() {

        try {
            return await this.get({ endpoint: 'coupons/types', data: {} })
        } catch (error) {
            throw error;
        }
    }


    async getCouponUsage(id) {

        try {
            return await this.get({ endpoint: `coupons/${id}/usage` })
        } catch (error) {
            throw error;
        }
    }

}