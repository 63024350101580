import React, { useContext, useState, useEffect } from 'react';
import GlobalStateContext from "../context";
import StreamWebService from "../classes/StreamWebService";
import { SET_ITEM } from "../store/actions/types";
import EmptyStream from "../pages/protected/streams/components/EmptyStream";
import LoadingCards from "../components/LoadingCards";
import PublicStreamCard from './PublicStreamCard';
import _ from "lodash"
import CustomSearch from '../pages/protected/streams/components/CustomSearch';


const PublicStreams = ({ title, list_type, exceptions = [] }) => {
    let webservice = new StreamWebService();
    const itemsPerPage = 15;
    var itemsSet = 1;
    const [state, dispatch] = useContext(GlobalStateContext);
    const [loading, setLoading] = useState(true);
    const [streams, setStreams] = useState([]);
    const [payload, setPayload] = useState({ page: itemsSet, perPage: itemsPerPage });
    const styles = {
        streams: {
            display: "flex",
            flexWrap: "wrap",
            flex: "auto",

        }
    }

    const fetchStreams = async () => {
        setLoading(true)
        await webservice.getOpenPublicStreams(payload)
            .then(res => res.data.entries)
            .then(entries => entries.filter(ent => exceptions.indexOf(ent.id) === -1))
            .then(_streams => setStreams([...streams, ..._streams]));
        setLoading(false);
    }

    const loadMore = () => {
        setPayload({ ...payload, page: payload.page + 1 })
        return null
    }


    useEffect(() => {
        fetchStreams(payload);
    }, [payload])
    useEffect(() => {
        fetchStreams();
    }, [])


    return (
        < div className>
            <div className='' hidden={streams.length > 0 && streams.length < itemsPerPage}>

                <CustomSearch
                    condition={payload}
                    onListUpdate={items => setStreams(items)}
                    onLoading={_loading => setLoading(_loading)}
                    webservice={_ => webservice.getOpenPublicStreams(_)}
                />
            </div>
            {title || ''}

            {
                loading ? <LoadingCards /> :
                    streams.length > 0 ?
                        <>
                            <div className={list_type === "list" ? 'grid grid-cols-2 sm:grid-cols-3 md md:grid-cols-4 lg:grid-cols-6  mt-6 gap-x-5 gap-y-5 ' : 'row'}  style={{overflowX:"scroll",maxHeight:"70vh"}} >
                                {
                                    streams.map(item => <PublicStreamCard key={item.id} item={item} type={list_type} />)
                                }
                            </div>
                            <div className="flex justify-center text-center w-full card p-t-10 p-b-10 cursor-pointer">

                                <span onClick={loadMore}>Load more...</span>
                            </div>
                        </>
                        :
                        <EmptyStream message="No Live events" description="No live event found" />
            }
            
        </div>
    );
}

export default PublicStreams;