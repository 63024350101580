import React  from 'react'
import { withRouter } from 'react-router-dom'
import  ReactGA from "react-ga";
import env from "../environments";
import * as PropTypes from "prop-types";

class GoogleAnalytics extends React.PureComponent {

    constructor(props) {
        super(props);
        const {history} = this.props;
        ReactGA.initialize(env.googleServices.analytics);
        ReactGA.pageview(window.location.pathname + window.location.search);

        history.listen(() => {
            ReactGA.pageview(history.location.pathname + history.location.search);
        })
    }
    render() {
        return (
            <>
                {this.props.children}
            </>
        )
    }
}

GoogleAnalytics.propTypes = {children: PropTypes.any}
export default withRouter( GoogleAnalytics);


