import React from 'react'

export default function Greetings({ user, title, description }) {
  return (
    <div className='greetings text-left'>
      <h5 className='text-gray-500'> {title ? title : `Hi 👋, ${user?.name || ""}`}</h5>
      <div className='sub text-gray-300'> {description ? description : "We are happy to see you."}</div>
    </div>
  )
}
