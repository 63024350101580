import Webservice from './WebService';



export default class PackageWebService extends Webservice {

    async getPackages() {

        try {
            return await this.get({ endpoint: 'packages', data: {sort:{ price :1 }} })
        } catch (error) {
            throw error;
        }
    }


    async getFeatureTypes() {

        try {
            return await this.get({ endpoint: 'packages/featureTypes', data: {} })
        } catch (error) {
            throw error;
        }
    }

    /**
     * Saves Users info
     * @param {*} param0 
     */
    async save({data, id}) {

        try {
            return id ? 
            await this.put({ endpoint: `packages/${id}`, data }) : 
            await this.post({ endpoint: `packages`, data })
        } catch (error) {
            throw error;
        }
    }
 

    async getPackage(id) {
        try {
            return  await this.get({ endpoint: `packages/${id}`}); 
        } catch (error) {
            throw error;
        }
    }


    async getUnlimtedFreePackage() {
        try {
            return  await this.get({ endpoint: `packages/unlimtedFree`}); 
        } catch (error) {
            throw error;
        }
    }





    async deletePackage(id) {
        try {
            return  await this.delete({ endpoint: `packages/${id}`}); 
        } catch (error) {
            throw error;
        }
    }

}