import React, { useEffect } from 'react'
import styled from 'styled-components'
import styles from "./base.scss"

export default function BaseBanner(props) {


  const Text = styled.span`
    
  
    background-color: ${props?.style?.text?.background};
    color: ${props?.style?.text?.color};
    display: block;
    width: 200%;
    position: relative;
    overflow: hidden;
    animation: marquee 15s linear infinite;
    `

useEffect(() => {

  console.log("test",props)
  
}, [])



  return (
    <div className='baseBanner'>
      <div className="marquee">
        
         <Text className='text'>{ props.text ?? "You spin me right round, baby. Like a record, baby."}</Text>
      
      </div>
    </div>
  )
}
