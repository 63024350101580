import React, { useState, useContext, useEffect } from 'react'
import { Button, message, Avatar } from 'antd'
import GlobalStateContext from '../context';

import PropTypes from 'prop-types';
import env from "../environments";
import { ucFirst } from '../utils';


 function GetPlatformToken(props) {
    const availableProviders = ['twitch','facebook','youtube'];
    const [loading, setLoading] = useState(false);
    const [state, dispatch] = useContext(GlobalStateContext);


    const { io,user } = state;

    // webservice.baseUrl = env.app.api.baseUrlIntegration
    const cleanup = () => {
        setLoading(false);
        io.removeAllListeners(`platformtoken_${props.provider}_${props.key}`)
        io.removeAllListeners(`platformtoken_${props.provider}_${props.user?._id}`)
        io.removeAllListeners(`platformtoken_${props.provider}_${props.destination_id}`)
    }

    /**
     * Test Callback when io event fires. This in turns
     */
    function ioCallback(token) {
        if (props.callback) {
            props.callback(token);
        }
        setLoading(false);
    }

    useEffect(() => {
        console.log(props)
        props.trigger && onClick()
        return () => {
            cleanup();
        }
    }, [props.provider]);

    /**
     * Opens up a new browser window that requests for user twitch token
     */

    const onClick = async () => {
        setLoading(true);
        console.log(props)
        
        let endpoint = `${env.app.api.baseUrlIntegration}/${props.provider}?usr=${user._id}&type=${props.type}`;
        if(props.destination_id){
            endpoint = `${endpoint}&dest=${props.destination_id}`
        }
    
        window.open(endpoint);
        //set event listener
        console.log(`platformtoken_${props.provider}_${user._id}`);
        io.on( `platformtoken_${props.provider}_${props.id}`, ioCallback);
        io.on( `platformtoken_${props.provider}_${user._id}`, ioCallback);
        io.on( `platformtoken_${props.provider}_${props.destination_id}`, ioCallback);
    }
    return (
        
        !props.trigger  && 
        availableProviders.indexOf(props.provider) !== -1 ?
            <div className="text-center">
                <div>
                <Button size="large"  onClick={onClick} loading={loading}>
                   <Avatar shape="square" src={props.logo} /> Connect { props.type.split("_").map(t=>ucFirst(t)).join(" ")} 
                </Button>

                </div>
              
            </div>
            :
            <></>
    )
}


GetPlatformToken.propTypes = {
    id: PropTypes.string,
    provider: PropTypes.string,
    callback: PropTypes.func
}


export default GetPlatformToken;