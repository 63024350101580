import { Typography, Icon, Drawer, Button, Modal } from 'antd';
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect';
import environments from '../../../../environments';
import { ShareSocial } from 'react-share-social';
import { FaShare } from 'react-icons/fa';

export default function ShareStreamComponent({ item,className, text }) {

    const [showShareDrawer, setShowShareDrawer] = useState(false);
    const shareButtonStyle = {
        root: {
            // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            background: 'rgba(59, 130, 246, var(--tw-bg-opacity))',
            borderRadius: 3,
            border: 0,
            // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            color: 'white',
            padding:"0",
            maxWidth:"100%"

        },
        copyContainer: {
            border: '1px solid blue',
            background: 'rgb(0,0,0,0.7)'
        },
        title: {
            color: 'white',
            fontStyle: 'italic'
        },
        copyBtn:{
            color: 'white',
        }

    };

    return (
        <>

            <button className={className || "bg-white text-gray-500 py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400  "} onClick={_ => setShowShareDrawer(true)}>
                 <FaShare className='inline m-1' />  {text || 'Share'}  </button>
            <Modal
            footer={false}
                title="Share Event"
                placement="left"
               
                onCancel={_ => setShowShareDrawer(false)}
                visible={showShareDrawer}
                width={isMobile ? '100%' : '40%'}
            >

                <div className="container">
                    <ShareSocial 
                    socialTypes={["whatsapp",'facebook','twitter','email']} 
                    
                    style={shareButtonStyle} url={`${environments.app.url}/${item.id}/watch`} />

                    <div className='mt-4 border sm:p-2 lg:p-5'>

                        <h6 className="text-lg ">
                            Embed
                        </h6>

                        <p>Copy the code below to embed </p>

                        <div className='bg-gray-100 p-2 rounded'>

                            <Typography.Text
                                
                                copyable 
                                
                                style={{ maxWidth: "60px" }}>
                                {
                                    `<iframe src='${environments.app.url}/embed/${item.id}' 
                        title='${item.title}' allowFullScreen
                        frameBorder="0" allow="accelerometer; 
                        autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        ></iframe>`
                                }
                            </Typography.Text>

                        </div>

                    </div>
                </div>
            </Modal>

        </>
    )
}
