import { useReducer } from 'react';
import { isMobile } from 'react-device-detect';
import SocketIOClient from "socket.io-client";
import { ACCOUNT_TYPE_BUSINESS, ACCOUNT_TYPE_PERSONAL } from '../constants';
import env from '../environments';
import reducer from './reducer';

//Initializing socket io globally object
const { api } = env.app;
const io = new SocketIOClient(api.socket, { secure: true });
const fallbacks = {
    currency: { code: "USD", "name": "United States Dollars", symbol: "$" }
}

const eventCategories = [
    'gaming', 'how-to', 'music', 'technology', 'workout',
    'liveband', "movie", 'fun', 'sport', 'trade', 'crypto', 'love',
    'forex', 'bible', 'teaching', 'mathematics',
    "other"
]


const initialState = {
    app : env.app,
    draggable: !isMobile,
    active_streams: [],
    referer: "",
    eventCategories,
    isLive: false,
    test_mode: true,
    image_data: "",
    user: false,
    authenticated: false,
    package_frequencies: ["day", "week", "month", "year"],
    userAccountTypes: [ACCOUNT_TYPE_PERSONAL, ACCOUNT_TYPE_BUSINESS],
    configuration: {},
    notifications: [],
    packages: [],
    active_subscription: false,
    stream_types: [],
    stream_destinations: [],
    streams: [],
    io,
    features_accessible: {},
    feature_types: {},
    userLocationData: {},
    currency: fallbacks.currency,
    xrate: 1.00,
    collapsed_nav: true,
    hide_nav: false,
    show_update_prompt: false,
    app_version: null,
    introSteps: {
        // "emptyStream": [
        //     {
        //         placementBeacon:'top',
        //         target: '#createStreamButton',
        //         content: 'Click this buttom to create your first stream record',
        //         // event:'hover',
        //         placement:"auto"
        //     },
        // ],
        // "createStreamForm": [

        //     {
        //         target: '#streamTitle',
        //         content: 'Give your stream a Title. Eg. Birthday Party',
        //         placement:"auto",
        //         event:'hover',
        //         disableBeacon:false
        //     }
        //     ,
        //     {
        //         target: '#streamDescription',
        //         content: 'Lets give a short description so our viewers will know what this is about.',
        //         placement:"auto",
        //         event:'hover',
        //         disableBeacon:false
        //     }
        //     ,
        //     {
        //         target: '#streamCategory',
        //         content: "Specify a category. This will help us suggest this video to users with similar interest",
        //         placement:"auto",
        //         event:'hover',
        //         disableBeacon:false
        //     }
        //     ,
        //     {
        //         target: '#streamBanner',
        //         content: "Now upload a catchy image to attract users.",
        //         placement:"auto",
        //         event:'hover',
        //         disableBeacon:false
        //     }
        //     ,
        //     {
        //         target: '#streamVisibility',
        //         content: `If you want your stream to be viewable by ${env.app.name} users set it to public else set it to private.`,
        //         placement:"auto",
        //         event:'hover',
        //         disableBeacon:false
        //     },
        //     {
        //         target: '#streamSubmit',
        //         content: `Now save and let's proceed to the studio. `,
        //         placement:"auto",
        //         event:'hover',
        //         disableBeacon:false
        //     }
        // ],

        // "viewStream": [
        //     {
        //         target: '#deploymentSection',
        //         content: 'You can choose to stream from your browser webcam or externally',
        //         placement:"auto",
        //         event:'hover',
        //         disableBeacon:false


        //     },
        //     {
        //         target: '#addDestinationButton',
        //         content: 'Add a stream destination. Eg facebook, youtube etc',
        //         placementBeacon:'auto',
        //         event:'hover',
        //         disableBeacon:false
        //     },
        //     // {
        //     //     target: '#destinationForm',
        //     //     content: 'Select your prefered stream destination, provide the stream token and/or URL ',
        //     //     placementBeacon:'auto',
        //     //     event:'hover',
        //     // }
        // ],
    },
    streamMediaConfig:{}

    // ,


};

const useGlobalState = () => {
    return useReducer(reducer, initialState)
};


export default useGlobalState;
