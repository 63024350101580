import React, { useContext } from 'react';
import { NewStreamButton } from './NewStreamButton';
import GlobalStateContext from '../../../../context';
import Rider from '../../../../components/Rider';
import emptySpace from '../../../../assets/images/empty.svg'
import { Link } from 'react-router-dom';
import { url } from '../../../../utils';

const EmptyStream = (props) => {

    const [state] = useContext(GlobalStateContext)
    const { message, description } = props

    return (
        <>
            <Rider steps={state.introSteps.emptyStream} />
            <div className='introduction'>
                <div className="title"> {message ? message : 'Empty Space'} </div>

                <div className='flex justify-center'>
                    <img src={emptySpace} style={{ maxWidth: "70vh", width: '100%' }} />

                </div>

                {/* <Empty

                    description= {description? description : "You don't have anything to show yet"}
                /> */}
                <p>{description ? description : "You don't have anything to show yet"}</p>
                <NewStreamButton block={false} type="warning" icon="deployment-unit">
                    Create Event
                </NewStreamButton>
                <div>

                    <Link to={url("others", true)} className=""> See what others are streaming</Link>
                </div>
            </div>
        </>
    )
}


export default EmptyStream;