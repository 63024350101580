import React, { useEffect, useRef, useState, useCallback } from 'react';

// import './AudioVisualizerHtml.css';
import userImage from '../../../../../assets/images/user3.png';

const AudioVisualizer = ({ mediaStream, label }) => {
  const [audioData, setAudioData] = useState([]);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const circleRefOuter = useRef(null);
  const circleRef = useRef(null);
  
  // Configurable settings with reasonable defaults
  const sensitivity = 5.9; // Reduce this value to make it less sensitive
  const smoothingTimeConstant = 0.8; // Higher value = smoother animation
  const minRadius = 10;
  const maxRadius = 20;
  
  const initializeAudioContext = useCallback(() => {
    if (!mediaStream) return;
    console.log("[x]initializeAudioContext mediastream set")
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    
    // Configure analyser for smoother visualization
    analyser.smoothingTimeConstant = smoothingTimeConstant;
    analyser.minDecibels = -90;
    analyser.maxDecibels = -10;
    analyser.fftSize = 64; // Reduced for better performance
    
    const source = audioContext.createMediaStreamSource(mediaStream);
    source.connect(analyser);
    analyserRef.current = analyser;
    
    return () => {
      source.disconnect();
      audioContext.close();
    };
  }, [mediaStream]);
  
  const drawVisualization = useCallback(() => {
    if (!analyserRef.current || !circleRef.current) return;
    console.log("[x]drawVisualization analyserRef set")
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    analyserRef.current.getByteFrequencyData(dataArray);
    
    // Calculate average amplitude with sensitivity adjustment
    const average = (dataArray.reduce((acc, val) => acc + val, 0) / bufferLength) * sensitivity;
    
    // Smooth radius calculation
    const normalizedRadius = Math.min(
      maxRadius,
      minRadius + ((average / 256) * (maxRadius - minRadius))
    );
    
    // circleRefOuter.current.setAttribute('r', normalizedRadius.toFixed(2)+ 5);
    circleRef.current.setAttribute('r', normalizedRadius.toFixed(2) );
    setAudioData(Array.from(dataArray));
    
    animationFrameRef.current = requestAnimationFrame(drawVisualization);
  }, [sensitivity]);
  
  useEffect(() => {
    const cleanup = initializeAudioContext();
    
    if (analyserRef.current) {
      drawVisualization();
    }
    
    return () => {
      if (cleanup) cleanup();
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [initializeAudioContext, drawVisualization]);

  return (
    <div className="relative w-full h-full bg-gray-800 rounded-lg p-2">
      <div className="overlay-top m-2 rounded-md">
                <div className='label '>
                    {/* <Icon type={iconMap[_type.toLowerCase() || 'webcam']} />  */}
                    {label}</div>
            </div>
      <svg 
        className="w-full h-full" 
        viewBox="0 0 100 100"
        style={{ filter: 'drop-shadow(0 0 4px rgba(0,0,0,0.3))' }}
      >
        {/* Background circle */}
        <circle 
          ref={circleRefOuter}
          cx="50" 
          cy="50" 
          r={maxRadius + 5}
          className="fill-gray-600"
        />
        
        {/* Animated circle */}
        <circle 
          ref={circleRef}
          cx="50"
          cy="50"
          r={minRadius}
          className="fill-blue transition-all duration-75 ease-out"
        />
        
        {/* User image */}
        <image 
          href={userImage} 
          x="35" 
          y="35" 
          width="30" 
          height="30" 
          className="rounded-full"
        />
        
        {/* Label */}
        {/* <text 
          x="50" 
          y="85" 
          textAnchor="middle" 
          className="text-xs fill-white font-medium"
          style={{ fontSize: '8px' }}
        >
          {label}
        </text> */}
      </svg>
    </div>
  );
};

export default AudioVisualizer;