import React, { useContext } from 'react';
import GlobalStateContext from '../context';

export default function LocalMoneyValue({ 
    value = 0.00, 
    showSymbol = false ,
    prefixText="",
    postfixText=""
}) {
    let finalVal = 0;
    const [state] = useContext(GlobalStateContext)
    const {currency,xrate } = state;
    const newvalue = parseFloat(value) * parseFloat(xrate);
    finalVal = newvalue || value;
    const symbol = currency.symbol 

    return (
        <>
        <span className=''> {prefixText}{showSymbol ? symbol: null }</span>  {finalVal.toFixed(2)}{postfixText}
        </>
    )

}
