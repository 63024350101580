import React from 'react';
import userImage from '../../../../assets/images/user2.png'

const AudioVisualizer = ({ stream }) => {
  const canvasRef = React.useRef(null);
  const audioContextRef = React.useRef(null);
  const analyserNodeRef = React.useRef(null);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyserNode = audioContext.createAnalyser();

    audioContextRef.current = audioContext;
    analyserNodeRef.current = analyserNode;

    const source = audioContext.createMediaStreamSource(stream.getMediaStream());
    source.connect(analyserNode);

    const drawWaveform = () => {
      const canvasContext = canvas.getContext('2d');
      const bufferLength = analyserNode.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      canvasContext.clearRect(0, 0, canvas.width, canvas.height);
      analyserNode.getByteTimeDomainData(dataArray);

      const dashWidth = 5; // Adjust this value for the desired dash width
      const dashGap = 5; // Adjust this value for the desired gap between dashes
      const totalDashLength = dashWidth + dashGap;
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const centerX = canvasWidth / 2;
      const centerY = canvasHeight / 2;

      const maxAmplitude = 128.0;
      const heightMultiplier = Math.min(centerX, centerY) / maxAmplitude;

      canvasContext.lineWidth = 2;
      canvasContext.strokeStyle = 'rgb(255, 255, 255)';
      canvasContext.beginPath();

      for (let i = 0; i < bufferLength; i++) {
        const amplitude = dataArray[i] - maxAmplitude;
        const dashHeight = Math.abs(amplitude) * heightMultiplier;

        const angle = (i / bufferLength) * Math.PI * 2;
        const radius = Math.min(centerX, centerY) - dashHeight;

        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);

        if (i % totalDashLength === 0) {
          canvasContext.moveTo(x, y);
        } else {
          canvasContext.lineTo(x, y);
        }
      }

      canvasContext.closePath();
      canvasContext.stroke();

      requestAnimationFrame(drawWaveform);
    };

    drawWaveform();

    return () => {
      // Disconnect the analyser node from the audio context
      analyserNode.disconnect();
      // Close the audio context
      audioContext.close();
    };
  }, [stream]);

  return (
    <div className='audioVisualizer' style={{ position: 'relative', display: 'flex', width: '100%' }}>
      <div className=" container" style={{ display: 'flex', justifyContent: 'center',backgroundImage: `url(${userImage})` }}>
        <canvas ref={canvasRef} width={200} height={300} style={{ margin: '0 auto', display: 'block' }} />
      </div>
    </div>
  );
};

export default AudioVisualizer;
