import React, { useEffect } from 'react'
import styled from 'styled-components'
import styles from "./image.scss"

export default function ImageBanner(props) {


  const Text = styled.span`
    
  
    background-color: ${props?.style?.text?.background};
    color: ${props?.style?.text?.color};
    display: block;
    width: 200%;
    position: relative;
    overflow: hidden;
    animation: marquee 15s linear infinite;
    `

useEffect(() => {

  console.log("test",props)
  
}, [])



  return (
    <div className='imageBanner'>
        <img src={props.image?.url} />
    </div>
  )
}
