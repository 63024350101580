import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Card, Collapse, Input, List, Tabs, Typography, Modal } from 'antd';
import GlobalStateContext from '../../../../context';
import Paragraph from 'antd/lib/typography/Paragraph';
import FileUpload from '../../../../components/FileUpload';
import BreakingNews from './mediaPannelBanners/breakingnews/BreakingNews';
import { BANNER_TYPE_BREAKINGNEWS, BANNER_TYPE_IMAGE } from '../../../../constants';
import { FaEdit, FaEye, FaEyeSlash } from "react-icons/fa";
import MediaPannelBanner from './mediaPannelBanners/MediaPannelBanner';
import _, { merge } from 'lodash';

export default function MediaPannelBanners(props) {

    const [gState, dispatch] = useContext(GlobalStateContext);
    const { mediaPanel } = gState
    const [state, setState] = useState(props?.banner)
    const [editIndex, setEditIndex] = useState(null)
    const [editState, setEditState] = useState({})

    const formRef = useRef()



    useEffect(() => {
        dispatch({
            type: "SET_ITEM",
            payload: ['mediaPanel', { ...mediaPanel, banner: state }]
        })
        console.log("Hello", state, mediaPanel);
    }, [state])

    useEffect(() => {

        if (editIndex) {

        }

    }, [editIndex])


    const convertStringToNestedObject = (string, value) => {
        const parts = string.split('.');
        let obj = value;
        for (const part of parts.reverse()) {
            obj = { [part]: obj };
        }
        return obj;
    }
    const onchange = (event) => {
        const { name, value } = event.target
        const currentState = state.banners[editIndex]
        const newInput = _.set(editState,name, value)
        
        const _state = merge(currentState,newInput)
        
        console.log(name, value, _state)
        setEditState(_state)


    }
    const onImagechange = (data) => {
       
        const currentState = state.banners[editIndex]
        const newData = { url: data?.location, name: data?.originalname }
        const newInput = _.set(editState,"image", newData)
        
        const _state = merge(currentState,newInput)
        
        setEditState(_state)


    }

    const onSave = () => {

        const current = state.banners[editIndex];


        state.banners[editIndex] = {
            ...current,
            ...editState
        }
        console.log(current, state.banners[editIndex], editState)
        setState({ ...state })
        setEditIndex(null)
        setEditState(null)

    }


    const onUse = (item) => {

    
        const activeIndex = state.active.findIndex(e => e?.type == item.type);
        console.log("txt ",activeIndex)
        if (activeIndex > -1) {
            state.active.splice(activeIndex, 1)
        } else {
        
            const banner = state.banners.find(e => e?.type == item.type);
            state.active.push(banner)
        }
        // state['banners'][index]['state'] = !state[index]['state']
        setState({ ...state })
    }
    const onEdit= (item) => {
        const index =  state.banners.findIndex(e => e?.type == item.type)
        console.log("INdex",index,state.banners)
        setEditIndex(index)
    }

    const onDoneUpload = (data, type = 'backdrops') => {
        console.log("[x] ",data)
        
        const newBanner = {
            "title": null,
            "text": null,
            "scroll": false,
            "style": {},
            "type": "image",
            image:{ url: data?.location, name: data?.originalname }
          }
        const newdata = [newBanner,...state[type], ]
        setState({...state,[type]:newdata});
        console.log("[x] ",newdata);

    }




    return (
        <div>

            <FileUpload
                    allowedTypes={['image/jpeg', 'image/png']}
                    maxFileSize={2}
                    showUploadList={false}
                    onComplete={res=> onDoneUpload(res.fileList[0]?.response?.data,"banners")}
                    className="w-full"
                    preview={false}
                    text={"Upload Banner"}
                    data={{ bucket: "viblica-assets" }}

                >

                    {/* <Button className='w-full ' > <IoMdAdd size={30}/> </Button> */}
                    <Button icon='upload' className='  rounded-lg w-full h-50 p-3 border m-3 border-blue-500'> Upload Banner </Button>
            </FileUpload>

            <List
                itemLayout='vertical'
                className="mediaPannelBanners"

                dataSource={state.banners}
                renderItem={(item, index) => {
                    console.log("term", state['active'], item);

                    const isActive = !!state['active'].find(t => (t?.type == item.type));
                    const isEdit = editIndex == index;

                    // const onEdit = indx => setEditIndex(indx == editIndex ? null : indx);



                    // <List.Item


                    //     actions={[
                    //         // <Button type="link" onClick={_ => onUse(index)} icon={isActive ? "eye-invisible" : "eye"}></Button>,
                    //         <Button type="link" onClick={_ => setEditIndex(index == editIndex ? null : index)} icon={isEdit ? "save" : "edit"}></Button>
                    //     ]}
                    // >


                    //     <div key={item.title} className={`banner ${item.scroll ? 'scrollable' : ''} ${isActive ? 'active' : ''}`}}
                    //     >

                    //         <div className='banner-title'>
                    //             {isEdit ? <Input size="small" placeholder="title here"
                    //                 defaultValue={item.title}
                    //                 onBlur={(str) => _onChange(str, index, "title")}

                    //             /> : item.title}

                    //         </div>

                    //         <div className={`banner-description   ${item.scroll ? 'scrolling-limit' : ''}`}>
                    //             {
                    //                 isEdit ?
                    //                     <Input size="small" placeholder="title here"
                    //                         defaultValue={item.text}
                    //                         onBlur={(str) => _onChange(str, index, "text")}

                    //                     /> :
                    //                     <Paragraph className={item.scroll ? 'scrolling' : ''}


                    //                     >{item.text}</Paragraph>
                    //             }
                    //         </div>




                    //     </div>
                    // </List.Item>
                    // return <BreakingNews title={item.title} text={item.text} />
                    return <div key={index } className={`group mb-3 ${isActive ? "active" : ""}`}>

                        <MediaPannelBanner key={index} banner={item} />

                        <div className='w-full flex  gap-2 justify-around w-full flex   gap-2 justify-around text-center '>
                            <button className='bg-gray-50 w-full p-1 hover:bg-gray-100 flex flex-row justify-center' onClick={_ => onUse(item)}>{isActive ? <FaEyeSlash size={15} /> : <FaEye size={15} />}</button>
                            <button className='bg-gray-50 w-full p-1 hover:bg-gray-100 flex flex-row justify-center' onClick={_ => onEdit(item)}>{<FaEdit size={15} />}</button>
                        </div>
                    </div>

                }}
            />

            <Modal
                title="Edit banner"
                visible={editIndex !== null}

                onCancel={_ => { setEditIndex(null); setEditState({}) }}

                onOk={onSave}



            >

                
                <form ref={formRef} onSubmit={onSave} >
                    {
                        state.banners[editIndex]?.type != BANNER_TYPE_IMAGE  &&
                  
                    <div className='flex flex-col gap-2 '>
                        <div className='flex flex-col border p-2'>
                            <label>Tile</label>
                            <div className='flex flex-col w-full justify-around'>
                                <div>
                                    <input onChange={onchange} name="title" className='bg-grey w-full p-2' type='text' placeholder='title' defaultValue={state.banners[editIndex]?.title} />
                                </div>

                                <div className='flex flex-row'>
                                    <div className='m-1'>
                                        <label className='w-full '>BG</label>
                                        <input onChange={onchange} name='style.title.background' className="" type='color' defaultValue={state.banners[editIndex]?.style?.title?.background} />
                                    </div>
                                    <div className='m-1'>
                                        <label className='w-full'>Text</label>
                                        <input onChange={onchange} name="style.title.color" className="" type='color' defaultValue={state.banners[editIndex]?.style?.text?.color} />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='flex flex-col border p-2'>
                            <label>Description</label>
                            <div className='flex flex-col w-full justify-around'>
                                <div>
                                    <input onChange={onchange} name="description" className='bg-grey w-full p-2' type='text' placeholder='description' defaultValue={state.banners[editIndex]?.text} />
                                </div>

                                <div className='flex flex-row'>
                                    <div className='m-1'>
                                        <label className='w-full'>BG </label>
                                        <input onChange={onchange} name='style.text.background' className="" type='color' defaultValue={state.banners[editIndex]?.style?.text?.background} />
                                    </div>
                                    <div className='m-1'>
                                        <label className='w-full'>Text</label>
                                        <input onChange={onchange} name="style.text.color" className="" type='color' defaultValue={state.banners[editIndex]?.style?.text?.color} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
  }

{
                        state.banners[editIndex]?.type == BANNER_TYPE_IMAGE  &&
                  
                    <div className='flex flex-col gap-2 '>
                        <img className='w-full h-9' src={state.banners[editIndex]?.image?.url}/>
                        <FileUpload
                            allowedTypes={['image/jpeg', 'image/png']}
                            maxFileSize={2}
                            showUploadList={false}
                            onComplete={res=> onImagechange(res.fileList[0]?.response?.data)}
                            className="w-full"
                            preview={false}
                            text={"Upload Banner"}
                            data={{ bucket: "viblica-assets" }}

                        >

                            {/* <Button className='w-full ' > <IoMdAdd size={30}/> </Button> */}
                            <button type='button' className='rounded-lg w-full h-50 p-3 border m-3 border-blue-500'> Change Image </button>
                    </FileUpload>   
                    </div>
  }
                </form>
            </Modal>


        </div>
    )
}