import React, { useEffect, useState } from 'react'
import BreakingNews from './breakingnews/BreakingNews'
import { BANNER_TYPE_BREAKINGNEWS, BANNER_TYPE_BASE, BANNER_TYPE_CLASSIC, BANNER_TYPE_IMAGE } from '../../../../../constants'
import BaseBanner from './original/BaseBanner'
import ClassicBanner from './classic/ClassicBanner'
import ImageBanner from './image/ImageBanner'


/**
 * This Component returns the right Banner based on the type
 * @returns 
 */
export default function MediaPannelBanner({ banner }) {

    const [Banner, setBanner] = useState()
    const componentMap = {
        [BANNER_TYPE_BREAKINGNEWS]: BreakingNews,
        [BANNER_TYPE_BASE]: BreakingNews
    }

    const  selectBanner = ()=>{
        if(banner){

            if (banner.type == BANNER_TYPE_BREAKINGNEWS) {
                return<BreakingNews title={banner.title} text={banner.description} style={banner.style} />
            }
            if (banner.type == BANNER_TYPE_CLASSIC) {
                return <ClassicBanner title={banner.title} text={banner.description} style={banner.style} />
            }
            
            
            if (banner.type == BANNER_TYPE_BASE){
                return <BaseBanner title={banner.title} text={banner.description} style={banner.style} />
            }
            if (banner.type == BANNER_TYPE_IMAGE){
                return <ImageBanner image={banner.image} style={banner.style} />
            }
        }
    }
    



    return (selectBanner())

}
