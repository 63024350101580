import Webservice from './WebService';



export default class DasbboardWebservice extends Webservice {

    async fetchDasboardData() {

        try {
            return await this.get({ endpoint: `dashboard` })
        } catch (error) {
            throw error;
        }
    }


}