
import Webservice from './WebService';



export default class StreamWebService extends Webservice {

    // constructor() {
    //     super()
    //     if (!StreamWebService.instance) {
    //       StreamWebService.instance = this;
    //     }
    
    //     return StreamWebService.instance;
    //   }

    async getStreams(payload) {

        try {
            return await this.get({ endpoint: 'streams', data: payload })
        } catch (error) {
            throw error;
        }
    }

    /**
     * 
     */
    async getStreamsTypes() {

        try {
            return await this.get({ endpoint: 'streams/types', data: {} })
        } catch (error) {
            throw error;
        }
    }
    
    /**
     * 
     */
    async getDestinations() {

        try {
            return await this.get({ endpoint: "streams/destinations", data: {} })
        } catch (error) {
            throw error;
        }
    }


    /**
     * Saves Streams info
     * @param {*} param0 
     */
    async save({ data, id }) {

        try {
            return id ?
                await this.put({ endpoint: `streams/${id}`, data }) :
                await this.post({ endpoint: `streams`, data })
        } catch (error) {
            throw error;
        }
    }


    async getStream(id) {
        try {
            return await this.get({ endpoint: `streams/${id}` });
        } catch (error) {
            throw error;
        }
    }
    async getInsight(id) {
        try {
            return await this.get({ endpoint: `streams/${id}/insight` });
        } catch (error) {
            throw error;
        }
    }
 
    async getStreamUnAuth(id) {
        try {
            return await this.get({ endpoint: `streams/${id}/unauth` });
        } catch (error) {
            throw error;
        }
    }


    async deleteStream(id) {
        try {
            return await this.delete({ endpoint: `streams/${id}` });
        } catch (error) {
            throw error;
        }
    }

    async toggleDestination( streamid,destId ) {
        try {
            return await this.put({ endpoint: `streams/${streamid}/destinations/${destId}/toggle`});
        } catch (error) {
            throw error;
        }
    }

    async addDestination({ data, id }) {
        try {
            return await this.post({ endpoint: `streams/${id}/destinations`,data });
        } catch (error) {
            throw error;
        }
    }


    async removeDestination({data, id }) {
        try {
         
            return await this.put({ endpoint: `streams/${id}/destinations/`,data});
        } catch (error) {
            throw error;
        }
    }


    async getPublicStreams(payload={}) {

        try {
            return await this.get({ endpoint: 'streams/public', data: payload })
        } catch (error) {
            throw error;
        }
    }

    async logView(id) {

        try {
            return await this.put({ endpoint: `streams/${id}/views`,data:{} })
        } catch (error) {
            throw error;
        }
    }


    async getOpenPublicStreams(payload={}) {

        try {
            return await this.get({ endpoint: 'streams/public/open', data: payload })
        } catch (error) {
            throw error;
        }
    }

    async getCategorizedStream(payload={}) {

        try {
            return await this.get({ endpoint: 'streams/catalogued', data: payload })
        } catch (error) {
            throw error;
        }
    }
    async getChat(streamId,payload={}) {

        try {
            return await this.get({ endpoint: `streams/${streamId}/chat`, data: payload })
        } catch (error) {
            throw error;
        }
    }
    async postComment(streamId,payload={}) {

        try {
            return await this.post({ endpoint: `streams/${streamId}/comment`, data: payload })
        } catch (error) {
            throw error;
        }
    }
    async confirmStop(streamId,payload={}) {

        try {
            return await this.post({ endpoint: `streams/${streamId}/confirmStop`, data: payload })
        } catch (error) {
            throw error;
        }
    }

}