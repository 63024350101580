
import React, { useState,useRef,useEffect } from 'react';
import Webservice from '../classes/WebService';
import StreamWebService from '../classes/StreamWebService';
import { Button } from 'antd';
import Countdown,{zeroPad} from 'react-countdown';

export default function InteruptionConfirmer({ callback, item }) { 
    const counter = useRef()
    const  [countTime,setcountTime] = useState(Date.now() + 300000)

    const handleSubmit = () => {
           
            const wb = new StreamWebService();
            wb.confirmStop(item.id)
            callback()
    }

   useEffect(() => {
     
   }, [])
   
    

  
    return (
        <div className="text-center mx-5"  >
            <h6 className='text-2xl text-bold mb-3'>
               Stream Ended 
            </h6>
            <div className="text-muted">
                <p className='text-md'>

                   Session will be terminated after the countdown. 
                   If this is due to techinical challenges, kindly make sure you get back online before countdown.
                   
                </p>
                <span className='text-xl mb-3'> 
                <Countdown ref={counter} date={countTime}  autoStart onComplete={handleSubmit} renderer={({minutes,seconds} )=>{

                     return <>{zeroPad(minutes)}:{zeroPad(seconds)} </>
                   }} />
                </span>
                 <div className='mt-3'>

                    <Button type='primary' onClick={handleSubmit}> 
                        Yes! End Session
                         </Button>
              
                 </div>
            </div>
        </div>
    )
}
